import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga';
import RouteChangeTracker from './components/ReactChangeTracker'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./Home";
import { Stores } from "./Stores";
import { HowCashbackWorks } from "./HowCashbackWorks";
import { Disclosure } from "./Disclosure";
import { Help } from "./Help";
import { AboutUs } from "./AboutUs";
import { Error404 } from "./Error404";
import { Layout } from "./components/Layout";
import { MainNav } from "./components/MainNav";
import { Footer } from "./components/Footer";
import { LoginSignupModal } from "./components/LoginSignupModal";
import { Jumbotron as Jumbo } from './components/Jumbotron';
import { PaypalConfirm } from './components/PaypalConfirm';
import { Privacy } from "./PrivacyPolicy";
import { Contact } from "./Contact";
import { Terms } from "./Terms";
import { Profile } from "./myaccount/Profile";
import { Trips } from "./myaccount/Trips";
import { Store } from './Store';
import { SearchResults } from './SearchResults';
import ScrollToTop from "./components/ScrollToTop";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { listCbcMerchants } from './graphql/queries';
import {store, useGlobalState} from 'state-pool';
import awsconfig from './aws-exports';

awsconfig.aws_appsync_authenticationType = "AWS_IAM";
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsconfig);

store.setState("storesList", null);

const TRACKING_ID = "UA-163385413-3"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [storesList, setStoresList] = useGlobalState("storesList");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('login')) {
      setIsOpen(true);
    }
    
    getLoggedInState();

    if (storesList === null) {
      let filter = {
          limit: 1000
      };

      API.graphql(graphqlOperation(listCbcMerchants, filter)).then((merchants) => {
          console.log(merchants.data.listCbcMerchants);
          setStoresList(merchants.data.listCbcMerchants.items.filter(w => w.isMerchantUsed == true));
      });
    }
  }, []);

  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const getLoggedInState = () => {
    Auth.currentAuthenticatedUser().then((user) => {
      setCurrentUser(user);
      console.log("User logged in: ", user);
      setLoggedIn(true);
    }).catch((error) => {
      console.log(error);
      console.log("User not logged in.");
      setLoggedIn(false);
    });
  }

  const onSignIn = () => {
    getLoggedInState();
  }

  return(
    <Router>
      <ScrollToTop />
      <MainNav modalOpenEvent={openModal} getLoggedInState={getLoggedInState} loggedInState={loggedIn} loggedInUser={currentUser} />
          <Switch>
            <Route exact path="/">
              <Jumbo />
              <Layout>
                <Home />
              </Layout>
            </Route>

            <Route path="/stores?:url">
              <Layout>
                <Stores />
              </Layout>
            </Route>

            <Route path="/stores">
              <Layout>
                <Stores />
              </Layout>
            </Route>

            <Route path="/store/:id">
              <Layout>
                <Store currentUser={currentUser} modalOpenEvent={openModal} />
              </Layout>
            </Route>
            
            <Route path="/how-cashback-works">
              <Layout>
                <HowCashbackWorks />
              </Layout>
            </Route>

            <Route path="/about-us">
              <Layout>
                <AboutUs />
              </Layout>
            </Route>

            <Route path="/help">
              <Layout>
                <Help />
              </Layout>
            </Route>

            <Route path="/affiliate-disclosure">
              <Layout>
                <Disclosure />
              </Layout>
            </Route>

            <Route path="/privacy-policy">
              <Layout>
                <Privacy />
              </Layout>
            </Route>

            <Route path="/contact-us">
              <Layout>
                <Contact />
              </Layout>
            </Route>

            <Route path="/terms">
              <Layout>
                <Terms />
              </Layout>
            </Route>

            <Route path="/myaccount/profile">
              <Layout>
                <Profile getLoggedInState={getLoggedInState} currentUser={currentUser} />
              </Layout>
            </Route>

            <Route path="/myaccount/mytrips">
              <Layout>
                <Trips currentUser={currentUser} />
              </Layout>
            </Route>

            <Route path="/paypalconfirm">
              <PaypalConfirm />
            </Route>

            <Route path="/search/:term">
              <SearchResults />
            </Route>

            <Route>
              <Layout>
                <Error404 />
              </Layout>
            </Route>
          </Switch>
          
        <LoginSignupModal stateVal={isOpen} closeModalVal={closeModal} onSignIn={onSignIn} closeModal={closeModal} />
        <Footer />
        <div>
          <RouteChangeTracker />
        </div>
    </Router>
  );
}

export default App;