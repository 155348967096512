import React from 'react';
import { Jumbotron as Jumbo, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import homeImage from '../assets/home-jumbo-background.jpg';
import { GoogleOAuth } from '@aws-amplify/core';

const Styles = styled.div`
    @media only screen and (max-width: 600px) {
        .jumbo {
            background: url(${homeImage}) no-repeat 40% 0%;
            background-size: cover;
            color: #fff;
            height: 468px;
            position: relative;
            text-align: right;
            align: right;
            color: #FFFFFF;
            text-shadow: 2px 2px 10px rgb(0 0 0 / 65%);
            font-family: "Open Sans",trebuchet ms;
            font-stretch: 100%;
        }
    }

    @media only screen and (min-width: 601px) {
        .jumbo {
            background: url(${homeImage}) no-repeat bottom;
            background-size: cover;
            color: #fff;
            height: 468px;
            position: relative;
            text-align: right;
            align: right;
            color: #FFFFFF;
            text-shadow: 2px 2px 10px rgb(0 0 0 / 65%);
            font-family: "Open Sans",trebuchet ms;
            font-stretch: 100%;
        }
    }

    .mainTagline {
        font-size: 50px !important;
        line-height: 55px;
        font-weight: 700;
    }

    .subTagline {
        font-size: 21px !important;
        line-height: 30px;
        font-weight: 700;
    }

    .cbcButton {
        background-color: #FFC000 !important;
        font-size: 16px;
        font-family: "Open Sans",trebuchet ms;
        color: #000;
        border-color: #000;
        cursor: pointer;
    }
    .cbcButton:hover {
        background-color: #ffd400 !important;
    }
`;

export const Jumbotron = (props) => {
    let history = useHistory();

    const goToCashback = () => {
        history.push("/how-cashback-works");
    }
    
    return (
        <Styles>
            <Jumbo fluid className="jumbo d-flex align-items-center">
                <Container>
                    <h1 className="mainTagline">Earn Cashback<br />Like a Champ</h1>
                    <p className="subTagline">Get Paid for Shopping Online</p>
                    <Button className="cbcButton" onClick={e => goToCashback()}>Learn More</Button>
                </Container>
            </Jumbo>
        </Styles>
    );
}