import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import styled from 'styled-components';
import { Auth } from 'aws-amplify';

const Styles = styled.div`
    .button {
        color: #FFFFFF;
        padding: 10px;
        width: 100%;
        border: none;
        border-radius: 5px;
    }

    .marginRight {
        margin-right: 5px;
    }

    .facebookButton {
        background-color: #4267B2;
    }

    .googleButton {
        background-color: #DB4437;
    }

    .amazonButton {
        background-color: #FF9900;
    }

    .separator {
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 1.5em;
    }
        
    .separator::before,
    .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
    }
    
    .separator:not(:empty)::before {
        margin-right: .25em;
    }
    
    .separator:not(:empty)::after {
        margin-left: .25em;
    }
`;

export const Oauth = (props) => {
    const signupWithFacebook = async() => {
        Auth.federatedSignIn({provider: 'Facebook'});
    }
    
    const signupWithGoogle = async() => {
        Auth.federatedSignIn({provider: 'Google'});
    }
    
    return (
        <Styles>
            <div>
                <h3 className="mt-2">{props.title}</h3>
                <p>{props.description}</p>
                
                <Container>
                    <Row>
                        { !props.useFacebook ? "" : 
                            <Col>
                                <button onClick={signupWithFacebook} className="facebookButton button">
                                    <FontAwesomeIcon icon={faFacebookF} className="marginRight" /> Continue with Facebook
                                </button>
                            </Col>
                        }
                        { !props.useGoogle ? "" : 
                            <Col>
                                <button onClick={signupWithGoogle} className="googleButton button">
                                    <FontAwesomeIcon icon={faGoogle} className="marginRight" /> Continue with Google
                                </button>
                            </Col>
                        }
                    </Row>
                </Container>

                <div className="separator">OR</div>
            </div>
        </Styles>
    );
}