import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import queryString from 'query-string';
import { Container, Row, Col, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { useGlobalState } from 'state-pool';
import ReactHtmlParser from 'react-html-parser'; 
import Pagination from './components/Pagination';

const Styles = styled.div`
    .store {
        margin-bottom: 10px;
    }

    .storeItem {
        padding: 20px 30px 20px 30px;
        text-align: center;
    }

    .letter {
        text-align: center;
    }

    h2 {
        font-size: 24px;
    }

    .pagination-bar {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

let PageSize = 48;

export const Stores = (props) => {
    const [storesList, setStoresList] = useGlobalState("storesList");
    const [currentPage, setCurrentPage] = useState(1);
    const { search } = useLocation();

    useEffect(() => {
        renderContent();
    }, [currentPage]);

    const renderContent = () => {
        const values = queryString.parse(search);
        let qCategory = values.category;
        let qLetter = values.letter;

        if (storesList !== null && storesList) {
            storesList.sort((a, b) => (a.name > b.name) ? 1 : -1);
            let updatedStoreList = null;

            if (qCategory && qCategory != null) {
                updatedStoreList = storesList.filter(x => x.categoryName.replaceAll("&", "") === decodeURI(qCategory.replaceAll("&", "")));
            } else if (qLetter && qLetter != null) {
                if (qLetter === "number") {
                    updatedStoreList = storesList.filter(x => x.name.startsWith("1") || x.name.startsWith("2") || x.name.startsWith("3") 
                        || x.name.startsWith("4") || x.name.startsWith("5") || x.name.startsWith("6") || x.name.startsWith("7") 
                        || x.name.startsWith("8") || x.name.startsWith("9") || x.name.startsWith("0"));
                } else {
                    updatedStoreList = storesList.filter(x => x.name.startsWith(qLetter));
                }
            } else {
                updatedStoreList = storesList;
            }

            let updatedStoreListCount = updatedStoreList.length;

            let stores = null;
            const firstPageIndex = (currentPage - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            
            if (updatedStoreList && updatedStoreList != null) {
                updatedStoreList = updatedStoreList.slice(firstPageIndex, lastPageIndex);
                stores = updatedStoreList.map((store) => {
                    return <Col key={store.id} className="storeItem"><Link to={"/store/" + store.id}><Image alt={store.name} title={store.name} src={"https://cashbackchamp-api-prod-storage-d2n49jw1g0fh57203816-staging.s3.amazonaws.com/" + store.id.replace("CJ-", "") + ".gif"} fluid /></Link></Col>
                });
            }

            const alphabetUp = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
            const letters = alphabetUp.map((l) =>
                <Col key={l} className="letter"><Link to={"/stores?letter=" + ((l === "#") ? "number" : l)}>{l}</Link></Col>
            );

            const categoryList = storesList.map(a => a.categoryName);
            var uniqueCategories = categoryList.filter((v, i, a) => a.indexOf(v) === i && v !== "");
            uniqueCategories.sort((a, b) => (a > b) ? 1 : -1);

            const cats = uniqueCategories.map((c) => {
                if (c !== null) {
                    <Col key={c}><Link to={"/stores?category=" + c.replaceAll("&", "")}>{c}</Link></Col>
                }
            });

            return (
                <div>
                    <Helmet>
                        <title>Earn Cashback from Over 500 Stores only at CashbackChamp.com</title>
                        <meta name="description" content="Do you shop online frequently? If so, you can earn cash back from over 500 stores only at CashbackChamp.com. It's 100% free!" />
                    </Helmet>
                    <h1>Stores</h1>
                    <p>Below is a list of all of our stores that you can shop and save big! Use the directory below to jump to a store and check out their special deals just for you!</p>
                    
                    <h2>Search by Letter</h2>
                    <Container>
                        <Row className="align-items-center">
                            {letters}
                        </Row>
                    </Container>
                    <br />

                    <h2>Search by Category</h2>
                    <Container>
                        <Row xs={2} sm={2} md={3} lg={4} className="align-items-center">
                            {cats}
                        </Row>
                    </Container>
                    <br />

                    {
                        (qLetter) ?
                            <span>
                                <h3>Stores Staring with the Letter "{qLetter}"</h3>
                            </span> 
                        : 
                            ""
                    }

                    {
                        (qCategory) ?
                            <span>
                                <h3>Stores in the Category "{qCategory}"</h3>
                            </span> 
                        : 
                            ""
                    }

                    {(qLetter || qCategory) ? 
                        <span><Link to="/stores">Show All Stores</Link><br /></span> 
                    : 
                        (!qLetter && !qCategory) ? 
                            <h3>All Stores</h3> 
                        : 
                            ""
                    }

                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={updatedStoreListCount}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                    <Container>
                        <Row xs={2} sm={2} md={3} lg={4} className="align-items-center">
                            {stores}
                        </Row>
                    </Container>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={updatedStoreListCount}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
            )
        } else {
            return (
                <div>
                    <h1>Stores</h1>
                    <p>Loading stores...please wait.</p>
                </div>
            )
        }
    }

    return (
        <Styles>
            { renderContent() }
        </Styles>
    );
}