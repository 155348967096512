import React from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { LoginForm } from './LoginForm';
import { Signup } from './Signup';

export const LoginSignupModal = (props) => {
    return (
        <Modal animation={false} size="lg" show={props.stateVal} onHide={props.closeModalVal}>
            <Modal.Header closeButton>
                <Modal.Title>Login / Sign Up</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="login" id="uncontrolled-tab-example">
                    <Tab eventKey="login" title="Login">
                        <LoginForm onSignIn={props.onSignIn} closeModal={props.closeModal} />
                    </Tab>
                    <Tab eventKey="signup" title="Sign Up" className="addBottomMargin">
                        <Signup onSignIn={props.onSignIn} closeModal={props.closeModal} />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.closeModalVal}>
                Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}