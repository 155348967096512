import { Form, Button } from 'react-bootstrap'
import styled from 'styled-components';
import axios from 'axios';
import {Helmet} from "react-helmet";

const Styles = styled.div`
    .error {
        color: red;
    }
`;

function submitContactForm(event) {
    event.preventDefault();

    const api = 'https://fzvib1kpw1.execute-api.us-east-1.amazonaws.com/prod';
    const data = { "name" : event.target.formName.value, "email" : event.target.formBasicEmail.value, "subject": event.target.formSubject.value, "message": event.target.formMessage.value };
    axios.post(api, data).then((response) => {
        if (response.status === 200) {
            document.getElementById("responseMessage").innerHTML = "<p>Your message has been sent. Thank you. Someone will respond to your email in 2-3 business days.</p>";
            document.getElementById("contactForm").reset();
        }
    })
    .catch((error) => {
        console.log(error);
    });
}

export const Contact = () => (
    <Styles>
        <Helmet>
            <title>Contact Us - CashbackChamp.com</title>
            <meta name="description" content="If you need help or have an inquiry, please fill out our contact us form to get in touch." />
        </Helmet>
        <div>
            <h1>Contact Us</h1>
            <p>
                To contact us, please fill out all of the fields in the contact form below. 
                We will try to get back to you within 24 hours.
            </p>
            <div id="responseMessage" className="error"></div>

            <Form onSubmit={submitContactForm} id="contactForm">
                <Form.Group controlId="formName">
                    <Form.Label>Your Name:</Form.Label>
                    <Form.Control type="text" placeholder="Enter name" />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <Form.Group controlId="formSubject">
                    <Form.Label>Subject:</Form.Label>
                    <Form.Control type="text" placeholder="Enter subject" />
                </Form.Group>

                <Form.Group controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={6} />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Send Message
                </Button>
            </Form>
        </div>
    </Styles>
)