import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { VerifyForm } from './VerifyForm';
import Parser from 'html-react-parser';
import { ForgotPassword } from './ForgotPassword';
import { Oauth } from './Oauth';
import { useHistory } from "react-router-dom";
import Lambda from 'aws-sdk/clients/lambda';

export const LoginForm = (props) => {
    let history = useHistory();
    const {onSignIn, closeModal, verificationMessage, initialEmail, giftCardGiveaway} = props;
    const [loginEmail, setLoginEmail] = useState(initialEmail);
    const [loginPassword, setLoginPassword] = useState('');
    const [showVerify, setShowVerify] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [loginError, setLoginError] = useState('');

    const signIn = async() => {
        Auth.signIn(loginEmail, loginPassword).then((user) => {
            if (user && user.attributes && user.attributes.email_verified) {
                setShowVerify(false);
                closeModal();
                onSignIn();
            } else {
                setShowVerify(true);
            }
        }).catch((error) => {
            if (error.code === "UserNotConfirmedException") {
                console.log("User not verified");
                console.log(error);
                setShowVerify(true);
            } else {
                console.log("Some other error.");
                console.log(error);
                setLoginError(error.message + ". Please try again.");
            }
        });
    }

    const goTo = (url) => {
        history.push(url);
    }

    const renderContent = () => {
        if (showForgot) {
            return <ForgotPassword setShowForgot={setShowForgot} title="Forgot Password" submitButtonText="Reset Password" />
        } else {
            if (showVerify) {
                return <VerifyForm onSignIn={onSignIn} closeModal={closeModal} initialEmail={loginEmail} />
            } else {
                return (
                    <div>
                        { !giftCardGiveaway ?
                            <Oauth title="Quick Sign In" description="To continue, sign in with Cashback Champ using one of these providers:" 
                            useFacebook={true} useGoogle={true} />
                        : 
                            ""
                        }
                        <h2 className="mt-2">Login</h2>
                        <p>To login with an existing account, please enter your email address and password below.</p>
                        { loginError !== "" ? <p><font color="red">{Parser(loginError)}</font></p> : '' }
                        { verificationMessage !== "" ? <p><font color="red">{verificationMessage}</font></p> : '' }
                        <Form>
                            <Form.Group controlId="loginEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={initialEmail} onChange={e => setLoginEmail(e.target.value)} />
                            </Form.Group>

                            <Form.Group controlId="loginPassword">
                                <Form.Label>Password</Form.Label>
                                <Button variant="link" onClick={e =>  setShowForgot(true)}>Forgot Password?</Button>
                                <Form.Control type="password" placeholder="Password" onChange={e => setLoginPassword(e.target.value)} />
                            </Form.Group>
                            <Button variant="primary" type="button" onClick={e => signIn()}>
                                Login
                            </Button>
                        </Form>
                    </div>
                );
            }
        }
    }

    return (
        <div>
            { renderContent() }
        </div>
    );
}