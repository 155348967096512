import React, { useEffect, useState } from 'react';
import {Table, Tabs, Tab} from 'react-bootstrap';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { clicksByDate, userPayoutsByDate } from '../graphql/queries';
import Moment from 'react-moment';
import {Helmet} from "react-helmet";

const Styles = styled.div`
`;

export const Trips = (props) => {
    const {currentUser} = props;
    const [trips, setTrips] = useState(null);
    const [totalEarnedThisPeriod, setTotalEarnedThisPeriod] = useState(0);
    const [payouts, setPayouts] = useState(null);

    useEffect(() => {
        if (currentUser && currentUser != null) {
            console.log("Username: ", currentUser.username);
            // Get all of the Trips
            API.graphql(graphqlOperation(clicksByDate, {userId: currentUser.username, sortDirection: "DESC"})).then((clicks) => {
                console.log("Clicks: ", clicks);
                let t = clicks.data.clicksByDate.items;
                setTrips(t);
                let e = 0;
                if (t.length > 0) {
                    t.forEach(function (t1) {
                        if (t1.userCommission) {
                            e += t1.userCommission;
                        }
                    });
                    setTotalEarnedThisPeriod(e);
                }
            }).catch((error) => {
                console.log("Error: ", error);
            });

            // Get all of the User Payouts
            API.graphql(graphqlOperation(userPayoutsByDate, {userId: currentUser.username, sortDirection: "DESC"})).then((payouts) => {
                setPayouts(payouts.data.userPayoutsByDate.items);
            }).catch((error) => {
                console.log("Error: ", error);
            });
        }
    }, [currentUser]);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    
    const renderTripContent = () => {
        if (currentUser === null) {
            return (
                <div>
                    <h2>No User Found</h2>
                    You must be signed in to view this page. Please click the Signup / Login link in the main menu.
                </div>
            );
        } else if (trips && trips.length > 0) {
            const mytrips = trips.map((trip) =>
                <tr key={trip.id}>
                    <td><Moment format="MMMM Do YYYY, h:mm:ss a">{trip.dateCreated}</Moment></td>
                    <td>{(trip.merchant) ? trip.merchant.name : ""}</td>
                    <td>{(trip.userCommission) ? formatter.format(trip.userCommission) : ""}</td>
                    <td>{(trip.dateEarned) ? <Moment format="MMMM Do YYYY, h:mm:ss a">{trip.dateEarned}</Moment> : ""}</td>
                    
                </tr>
            );
            return (
                <div>
                    <h1>My Trips</h1>
                    <p>
                        Below is a list of all of your shopping trips for the current period. A shopping trip is recorded every time you click
                        a link and are redirected to a store. If you have earned cashback for a trip, it will appear in the Cashback Earned and Date Earned columns.
                        If a shopping trip shows no earned data but you think it should, please be patient. It can sometimes take awhile for the merchant to inform us of your savings.
                        In order to earn cashback, you must make a purchase immediately after clicking a store's deal.
                    </p>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Trip Date</th>
                                <th>Store Name</th>
                                <th>Cashback Earned</th>
                                <th>Date Earned</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mytrips}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="2" className="text-right">Total Cashback Earned this Period:</th>
                                <th>{(totalEarnedThisPeriod) ? formatter.format(totalEarnedThisPeriod) : "$0.00"}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            );
        } else {
            return (
                <div>
                    <h1>My Trips</h1>
                    No Trips found. Please do some browsing of the site, make sure you are logged in and then click a merchant deal to record a trip.
                </div>
            );
        }
    }

    const renderEarningsContent = () => {
        if (currentUser === null) {
            return (
                <div>
                    <h2>No User Found</h2>
                    You must be signed in to view this page. Please click the Signup / Login link in the main menu.
                </div>
            );
        } else if (payouts && payouts.length > 0) {
            const mypayouts = payouts.map((payout) =>
                <tr key={payout.id}>
                    <td><Moment format="MMMM Do YYYY, h:mm:ss a">{payout.datePaid}</Moment></td>
                    <td>{formatter.format(payout.paymentAmount)}</td>
                    <td>{payout.paypalEmail}</td>
                    
                </tr>
            );
            return (
                <div>
                    <h1>My Earnings</h1>
                    <p>
                        Below are a list of all of your shopping trips. A shopping trip is recorded every time you click
                        a link and are redirected to a store. In order to earn cashback, you must make a purchase immediately
                        after clicking a store's deal.
                    </p>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Payout Date</th>
                                <th>Payment Amount</th>
                                <th>Paypal Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mypayouts}
                        </tbody>
                    </Table>
                </div>
            );
        } else {
            return (
                <div>
                    <h1>My Earnings</h1>
                    No Earnings were found. This means we haven't paid you yet. 
                    Be sure to browse Cashback Champ whenever you need to make a purchase and always ensure you are logged in 
                    and make your purchase immediately after clicking a merchant deal.
                </div>
            );
        }
    }

    return (
        <Styles>
            <Helmet>
                <title>My Trips - CashbackChamp.com</title>
                <meta name="description" content="View your most recent shopping trips tracked through CashbackChamp.com." />
            </Helmet>
            <Tabs defaultActiveKey="myTrips" id="tripsEarningsTabs" transition={false}>
                <Tab eventKey="myTrips" title="My Trips">
                    { renderTripContent() }
                </Tab>
                <Tab eventKey="myEarnings" title="My Earnings">
                    { renderEarningsContent() }
                </Tab>
            </Tabs>
        </Styles>
    );
}