import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import Countdown from 'react-countdown';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import { useGlobalState } from 'state-pool';
import { getCommission } from "./graphql/queries";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBullhorn, faCar, faCarCrash, faDice, faEye, faFilm, faFootballBall, faGavel, faGifts, faGlasses, faGlobeAmericas, faGraduationCap, faHome, faLaptop, faPaintBrush, faPalette, faPhone, faPiggyBank, faPlane, faRunning, faShoppingCart, faStore, faTshirt, faUmbrellaBeach, faUserMd, faUsers, faUserTie, faUtensils, faWind } from "@fortawesome/free-solid-svg-icons";
import { ConsoleLogger } from "@aws-amplify/core";

const Styles = styled.div`
    .homeHeading {
        margin-top: 60px;
        color: #F3A50D;
        font-weight: 700;
        font-family: "Open Sans",trebuchet ms;
        text-align: center;
    }

    .divider {
        color: #068143;
        border: none;
        height: 2px;
        background-color: #068143;
    }

    .subHead {
        color: #333;
        text-align: center;
        font-weight: 700;
        font-family: "Open Sans",trebuchet ms;
        font-style: italic;
    }

    .counter-digits {
        font-size: 36px;
        font-weight: 700;
        font-family: "Open Sans",trebuchet ms;
        color: #068143;
    }

    .counter-label {
        font-size: 24px;
        color: #000;
        margin-right: 20px;
        font-family: 'Pacifico', "Open Sans", trebuchet ms, sans-serif;
    }

    #counter {
        text-align: center;
    }

    .margin-bottom {
        margin-bottom: 20px;
    }

    .contest-button {
        background-color: #068143;
        color: white;
        font-size: 18px;
        font-weight: bold;
    }
    .contest-button:hover {
        color: yellow;
        background-color: #159915;
    }

    .store {
        margin-bottom: 10px;
    }

    .storeItem {
        padding: 20px 40px 20px 40px;
    }

    .category-card {
        border: 1px black solid;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        max-width: 265px;
    }

    .category-card:hover {
        background-color: #FFFFCC;
    }

    .catIcon {
        vertical-align: bottom;
    }

    .box {
        box-sizing: border-box;
        border: 3px solid transparent;
        background-clip:padding-box;
    }
`;

const Completionist = () => <span>Cashback Champ will be launching any minute now! Check back soon...</span>;
const launchDate = new Date(2022, 1, 14, 0, 0, 0, 0);
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return <div id="counter"><span className="counter-digits">{days}</span> <span className="counter-label">days</span> <span className="counter-digits">{hours}</span> <span className="counter-label">hours</span> <span className="counter-digits">{minutes}</span> <span className="counter-label">minutes</span> <span className="counter-digits">{seconds}</span> <span className="counter-label">seconds</span></div>;
    }
};

export const Home = (props) => {
    let history = useHistory();
    const [storesList, setStoresList] = useGlobalState("storesList");

    const signupWithFacebook = async() => {
        Auth.federatedSignIn({provider: 'Facebook'});
    }
    
    const signupWithGoogle = async() => {
        Auth.federatedSignIn({provider: 'Google'});
    }
    
    useEffect(() => {
        let loc = window.location.search;
        if (loc && /already.found.an.entry.for.username.google/gi.test(loc.toString(),)) {
            console.log("Error with google, call google again.");
            signupWithGoogle();
        } else if (loc && /already.found.an.entry.for.username.facebook/gi.test(loc.toString(),)) {
            console.log("Error with facebook, call facebook again.");
            signupWithFacebook();
        }
      }, []);

    const goToContest = () => {
        window.location.href = 'https://contest.cashbackchamp.com/lp/127353/lp127353';
    }

    const renderStoreList = () => {
        if (storesList !== null && storesList) {
            let storesList2 = storesList.filter(w => w.isMerchantUsed == true && w.featureStoreId != null);
            storesList2.sort((a, b) => (a.featureStoreId > b.featureStoreId) ? 1 : -1);
            const top20 = storesList2.slice(0, 20);
            const stores = top20.map((store) =>
                <Col key={store.id} className="storeItem"><Link to={"/store/" + store.id}><Image alt={store.name} title={store.name} src={"https://cashbackchamp-api-prod-storage-d2n49jw1g0fh57203816-staging.s3.amazonaws.com/" + store.id.replace("CJ-", "") + ".gif"} fluid /></Link></Col> 
            );

            return (
                <div>
                    <Container>
                        <Row xs={2} sm={2} md={3} lg={4} className="align-items-center text-center">
                            {stores}
                        </Row>
                    </Container>
                </div>
            )
        } else {
            return (
                <div>
                    <p>Loading stores...please wait.</p>
                </div>
            )
        }
    }

    const goTo = (c) => {
        history.push("/stores?category=" + c.replaceAll("&", ""));
    }

    const getIcon = (c) => {
        switch(c) {
            case "Accessories":
                return <FontAwesomeIcon icon={faGlasses} size="2x" className="catIcon" />;
            case "Art/Photo/Music":
                return <FontAwesomeIcon icon={faPaintBrush} size="2x" className="catIcon" />;
            case "Automotive":
                return <FontAwesomeIcon icon={faCar} size="2x" className="catIcon" />;
            case "Beauty":
                return <FontAwesomeIcon icon={faEye} size="2x" className="catIcon" />;
            case "Books/Media":
                return <FontAwesomeIcon icon={faBook} size="2x" className="catIcon" />;
            case "Business":
                return <FontAwesomeIcon icon={faUserTie} size="2x" className="catIcon" />;
            case "Buying and Selling":
                return <FontAwesomeIcon icon={faShoppingCart} size="2x" className="catIcon" />;
            case "Careers":
                return <FontAwesomeIcon icon={faUserMd} size="2x" className="catIcon" />;
            case "Clothing/Apparel":
                return <FontAwesomeIcon icon={faTshirt} size="2x" className="catIcon" />;
            case "Computer & Electronics":
                return <FontAwesomeIcon icon={faLaptop} size="2x" className="catIcon" />;
            case "Department Stores/Malls":
                return <FontAwesomeIcon icon={faStore} size="2x" className="catIcon" />;
            case "Education":
                return <FontAwesomeIcon icon={faGraduationCap} size="2x" className="catIcon" />;
            case "Entertainment":
                return <FontAwesomeIcon icon={faFilm} size="2x" className="catIcon" />;
            case "Family":
                return <FontAwesomeIcon icon={faUsers} size="2x" className="catIcon" />;
            case "Financial Services":
                return <FontAwesomeIcon icon={faPiggyBank} size="2x" className="catIcon" />;
            case "Food & Drinks":
                return <FontAwesomeIcon icon={faUtensils} size="2x" className="catIcon" />;
            case "Games & Toys":
                return <FontAwesomeIcon icon={faDice} size="2x" className="catIcon" />;
            case "Gifts & Flowers":
                return <FontAwesomeIcon icon={faGifts} size="2x" className="catIcon" />;
            case "Health and Wellness":
                return <FontAwesomeIcon icon={faRunning} size="2x" className="catIcon" />;
            case "Home & Garden":
                return <FontAwesomeIcon icon={faHome} size="2x" className="catIcon" />;
            case "Insurance":
                return <FontAwesomeIcon icon={faCarCrash} size="2x" className="catIcon" />;
            case "Legal":
                return <FontAwesomeIcon icon={faGavel} size="2x" className="catIcon" />;
            case "Marketing":
                return <FontAwesomeIcon icon={faBullhorn} size="2x" className="catIcon" />;
            case "Online Services":
                return <FontAwesomeIcon icon={faGlobeAmericas} size="2x" className="catIcon" />;
            case "Recreation & Leisure":
                return <FontAwesomeIcon icon={faUmbrellaBeach} size="2x" className="catIcon" />;
            case "Seasonal":
                return <FontAwesomeIcon icon={faWind} size="2x" className="catIcon" />;
            case "Sports & Fitness":
                return <FontAwesomeIcon icon={faFootballBall} size="2x" className="catIcon" />;
            case "Telecommunications":
                return <FontAwesomeIcon icon={faPhone} size="2x" className="catIcon" />;
            case "Travel":
                return <FontAwesomeIcon icon={faPlane} size="2x" className="catIcon" />;
            default:
                return "";
        }
    }

    const renderCategoryList = () => {
        if (storesList !== null && storesList) {
            const categoryList = storesList.map(a => a.categoryName);
            var uniqueCategories = categoryList.filter((v, i, a) => a.indexOf(v) === i && v !== "" && v !== null);
            uniqueCategories.sort((a, b) => (a > b) ? 1 : -1);

            const cats = uniqueCategories.map((c) =>
                <Col key={c} className="category-card box" onClick={e => goTo(c)}><div className="icon">{getIcon(c)}</div><div className="catName">{c}</div></Col>
            );

            return (
                <div>
                    <Container fluid>
                        <Row xs={2} sm={2} md={3} lg={4} className="align-items-center text-center">
                            {cats}
                        </Row>
                    </Container>
                </div>
            )
        } else {
            return (
                <div>
                    <p>Loading stores...please wait.</p>
                </div>
            )
        }
    }

    return (
        <Styles>
            <Helmet>
                <title>Earn Cashback Like a Champ with CashbackChamp.com</title>
                <meta name="description" content="CashbackChamp.com allows you to earn money on every day online purchases that you make at your favorite retailers. It's 100% free and easy to use. Check it out!" />
            </Helmet>
            <div>
                <h2 className="homeHeading">Shop & Save Money with over 500 Merchants!</h2>
                <hr className="divider" />
                <div className="subHead">
                    CashbackChamp.com is the place to shop & save online.<br />
                    We offer coupons, deals and other cashback opportunities from 1000s of merchants.<br />
                    Here are some of our top merchants:
                </div>
                <div>
                    { renderStoreList() }
                </div>

                <h2 className="homeHeading">Shop by Category</h2>
                <hr className="divider" />
                <div className="subHead">
                    Find your favorite merchants based on our categories.
                </div>
                <div>
                    { renderCategoryList() }
                </div>
            </div>
        </Styles>
    );
}