import { Container, Row, Col, Image } from 'react-bootstrap'
import styled from 'styled-components';
import Anoulom from './assets/anoulom.png';
import Jeff from './assets/jeff.png';
import {Helmet} from "react-helmet";

const Styles = styled.div`

    @media only screen and (max-width: 600px) {
        .textCenter {
            text-align: center;
            font-size: 11px;
            font-family: "Open Sans",trebuchet ms;
        }
    }

    @media only screen and (min-width: 601px) {
        .textCenter {
            text-align: center;
            font-size: 16px;
            font-family: "Open Sans",trebuchet ms;
        }
    }

    .highlightedText {
        color: #339966;
        font-style: italic;
        text-align: center;
        font-size: 24px;
        font-family: "Open Sans",trebuchet ms;
    }

    .userName {
        font-weight: 900;
        font-size: 18px;
        font-family: "Open Sans",trebuchet ms;
    }

    .userTitle {
        font-style: italic;
        font-size: 14px;
        font-family: "Open Sans",trebuchet ms;
    }

    hr {
        margin-top: 40px;
        margin-bottom: 40px;
        border: 0;
        border-top: 1px solid #339966;
      }
`;

export const AboutUs = () => (
    <Styles>
        <Helmet>
            <title>About Us - CashbackChamp.com</title>
            <meta name="description" content="Learn about CashbackChamp.com and the real people who are behind this website. We're here to help you save money online." />
        </Helmet>
        <div>
            <h1>About Us</h1>
            <p className="textCenter">
                Are you tired of searching endlessly online to find the right deals?<br />
                We offer coupons & cash back to your favorite online stores.<br />
                Here with the best offers to help you save time & money.<br />
                Search for what you need and start saving right away.
            </p>
            <p className="highlightedText">
                Earn <strong>Cashback</strong> like a <strong>Champ</strong>
            </p>
            <p>
                Cashback Champ is a completely free site that offers you free cash back through coupons and 
                special discounts from leading national and international online stores. 
            </p>
            <p>
                Our cash back program is straight forward. Simply put, we earn commission from our merchants and share it with you.    
            </p> 
            <p>
                Search for what you need and start saving right away. Sign up is free and easy!
            </p>

            <hr />

            <h2>Our Team</h2>
            <Container>
                <Row>
                    <Col xs={12} md={3}>
                        <Row>
                            <Col xs={12}>
                                <Image src={Anoulom} fluid />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="userName">Anoulom Soutdarany</div>
                                <div className="userTitle">Co-CEO</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={3}>
                        <Row>
                            <Col xs={12}>
                                <Image src={Jeff} fluid />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="userName">Jeff Stone</div>
                                <div className="userTitle">Co-CEO</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </Styles>
)