import React, { useState } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';

const Styles = styled.div`
    .noLeftPad {
        padding-left: 0px !important;
    }
`;

export const ForgotPassword = (props) => {
    const {setShowForgot, title, submitButtonText, isProfile, setShowResetPassword} = props;
    const [forgotError, setForgotError] = useState(''); 
    const [forgotEmail, setForgotEmail] = useState(''); 
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
    
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState(''); 

    const forgotPasswordSubmit = async() => {
        try {
            if (forgotEmail === "") {
                setForgotError("You must enter a valid email address into the field below. Please try again.");
            }
            else {
                Auth.forgotPassword(forgotEmail)
                .then(data => {
                    setShowResetPasswordForm(true);
                })
                .catch(error => {
                    if (error.code === "UserNotFoundException") {
                        setForgotError("There was an error retrieving your account. Please try again.");
                    } else {
                        setForgotError(error.message + ". Please try again.");
                    }
                });
            }
        }
        catch (error) {
            setForgotError(error.message + ". Please try again.");
        }
    }

    const resetPassword = async() => {
        try {
            if (verificationCode === "") {
                setForgotError("You must enter a valid verification code. Please fix and try again.");
            } else if (forgotEmail === "") {
                setForgotError("You must enter a valid email address. Please fix and try again.");
            } else if (newPassword === "") {
                setForgotError("You must enter a new password. Please fix and try again.");
            } else if (confirmNewPassword === "") {
                setForgotError("You must enter a confirm new password. Please fix and try again.");
            } else if (newPassword.trim().lenth < 8) {
                setForgotError("Your new password must be at least 8 characters long. Your password is only " + newPassword.trim().lenth + " character(s) long. Please fix and try again.");
            } else if (newPassword !== confirmNewPassword) {
                setForgotError("The New Password and Confirm New Password fields must match. Please fix and try again.");
            } else {
                Auth.forgotPasswordSubmit(forgotEmail, verificationCode, newPassword)
                .then(data => {
                    if (isProfile) {
                        setResetPasswordError("Your password has been successfully reset. Now redirecting you back to Update Your Profile.");
                        setTimeout(backToProfile, 3000);
                    } else {
                        setResetPasswordError("Your password has been successfully reset.");
                        setTimeout(setShowForgot, 3000);
                    }
                })
                .catch(err => {
                    setResetPasswordError(err.message);
                });
            }
        }
        catch (error) {
            setForgotError(error.message + ". Please try again.");
        }
    }

    const backToProfile = async() => {
        setShowResetPassword(false);
    }

    return (
        <Styles>
            <h2 className="mt-2">{title}</h2>
            {isProfile ? <Button className="noLeftPad" variant="link" onClick={e => setShowResetPassword(false)}>Back to Profile</Button> : ""}
            {showResetPasswordForm ?
                <div>
                    <p>
                        <font color="red">
                            We have emailed you a verification code. Please enter the verification code below and then enter your new password. 
                            Then, click Reset Password to change your password.
                        </font>
                    </p>
                    { resetPasswordError !== "" ? <p><font color="red">{resetPasswordError}</font></p> : '' }
                    <Form>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Group controlId="verificationCode">
                                        <Form.Label>Reset Password Code</Form.Label>
                                        <Form.Control type="text" placeholder="Enter verification code" onChange={e => setVerificationCode(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="newPassword">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password" placeholder="New Password" onChange={e => setNewPassword(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="confirmNewPassword">
                                        <Form.Label>Confirm New Password</Form.Label>
                                        <Form.Control type="password" placeholder="Confirm New Password" onChange={e => setConfirmNewPassword(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>

                        <Button variant="primary" type="button" onClick={resetPassword}>
                            {submitButtonText}
                        </Button>
                    </Form>
                </div>
            :
                <div>
                    <p>Please enter your email address below and we will email you a verification code to reset your password.</p>
                    { forgotError !== "" ? <p><font color="red">{forgotError}</font></p> : '' }

                    <Form>
                        <Form.Group controlId="forgotEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={e => setForgotEmail(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" type="button" onClick={forgotPasswordSubmit}>
                            Send Email
                        </Button>
                    </Form>
                </div>
            }
        </Styles>
    );
}