import React from 'react';
import { Nav, Navbar, Form, FormControl, Button, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import Logo from '../assets/cbc-logo.png';
import { Search } from 'react-bootstrap-icons';
import { useHistory, withRouter } from "react-router-dom";
import { Auth } from 'aws-amplify';

const Styles = styled.div`
    .navbar {
        background-color: #FFF;
    }

    .navbar-brand, .navbar-nav .nav-link {
        color: #000;

        &:hover {
            color: green;
        }
    }

    .searchFormWidth {
        width: 35%;
    }
`;

const getMyAccountText = (props) => {
    if (props.loggedInUser === null) {
        return "My Account";
    } else {
        if (props.loggedInUser.attributes["custom:given_name_overwrite"]) {
            return "Hi " + props.loggedInUser.attributes["custom:given_name_overwrite"] + "!";
        } else {
            return "Hi " + props.loggedInUser.attributes.given_name + "!";
        }
    }
}


export const MainNav = (props) => {
    let history = useHistory();

    const SearchButton = () => {
        const [showSearchBar, setShowSearchBar] = React.useState(false)
        const onClick = () => {
            if (document.getElementById('searchBar') && document.getElementById('searchBar').value !== "") {
                goTo("/search/" + document.getElementById('searchBar').value);
            }
            showSearchBar ? setShowSearchBar(false) : setShowSearchBar(true)
        }
        return (
            <div className="d-none d-lg-block">
                <Form inline className="d-none d-lg-block">    
                    { showSearchBar ? <SearchBar /> : null }
                    <Button variant="outline-success" onClick={onClick}><Search /></Button>
                </Form>
            </div>
        )
    }

    const SearchBar = () => (
        <FormControl id="searchBar" type="text" placeholder="Search" className="mr-sm-2" onKeyPress={handleKeyPress} />
    )

    const handleKeyPress = (target) => {
        if(target.charCode===13){
            target.preventDefault();
            goTo("/search/" + document.getElementById('searchBar').value);
        }
    }
      
    
    const goTo = (url) => {
        history.push(url);
    }

    const signOut = async() => {
        try {
            await Auth.signOut();
            props.getLoggedInState();
            goTo("/");
        } catch (error) {
            console.log("error signing out ", error);
        }
      }

    return (
        <Styles>
            <Navbar expand="lg">
                <Navbar.Brand onClick={() => goTo("/")}><img src={Logo} alt="Cashback Champ Logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item><Nav.Link onClick={() => goTo("/")}>Home</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link onClick={() => goTo("/stores")}>Stores</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link onClick={() => goTo("/how-cashback-works")}>How Cashback Works</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link onClick={() => goTo("/help")}>Help</Nav.Link></Nav.Item>
                        { props.loggedInState ?
                            <NavDropdown alignRight flip title={getMyAccountText(props)} id="navbarScrollingDropdown">
                                <NavDropdown.Item onClick={() => goTo("/myaccount/profile")}>Profile</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => goTo("/myaccount/mytrips")}>My Trips</NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        : 
                            <Nav.Item><Nav.Link onClick={props.modalOpenEvent}>Signup / Login</Nav.Link></Nav.Item> 
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Styles>
    )
}

export default withRouter(MainNav);