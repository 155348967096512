import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Container, Col } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Parser from 'html-react-parser';
import { VerifyForm } from './VerifyForm';
import { Oauth } from './Oauth';

export const Signup = (props) => {
    const [signUpFirstName, setSignUpFirstName] = useState('');
    const [signUpLastName, setSignUpLastName] = useState('');
    const [signUpEmail, setSignUpEmail] = useState('');
    const [signUpPassword, setSignUpPassword] = useState('');
    const [signUpConfirmPassword, setSignUpConfirmPassword] = useState('');
    const [signUpError, setSignUpError] = useState('');
    const [showVerify, setShowVerify] = useState(false);
    const {onSignIn, closeModal, giftCardGiveaway} = props;
    const [currentUser, setCurrentUser] = useState('');

    const signUp = async() => {
        try {
            // Make sure all of the fields are filled in
            let error = "";
            if (signUpFirstName === "") {
                error += "First Name is a required field.<br />";
            }
            if (signUpLastName === "") {
                error += "Last Name is a required field.<br />";
            }
            if (signUpEmail === "") {
                error += "Email is a required field.<br />";
            }
            if (signUpPassword === "") {
                error += "Password is a required field.<br />";
            }
            if (signUpConfirmPassword === "") {
                error += "Confirm Password is a required field.<br />";
            }
            if (signUpPassword.trim().length < 8) {
                error += "Your password must be at least 8 characters long. You only enetered " + signUpPassword.trim().length + " character(s).<br />";
            }
            if (signUpPassword !== signUpConfirmPassword) {
                error += "Password and Confirm Password must match each other.<br />";
            }
            if (error === "") {
                const { user } = await Auth.signUp({
                    'username': signUpEmail,
                    'password': signUpPassword,
                    attributes: {
                        'email': signUpEmail,
                        'given_name': signUpFirstName,
                        'family_name': signUpLastName
                    }
                });
                console.log("Signed Up User: ", user);
                
                if (user.authenticationFlowType === "USER_SRP_AUTH") {
                    setShowVerify(true);
                }
            } else {
                error += "<br />";
                setSignUpError(error);
            }
        } catch (error) {
            setSignUpError(error.message + ". Please try again.");
        }
    }

    return (
        <div>
            {showVerify ? <VerifyForm onSignIn={onSignIn} closeModal={closeModal} initialEmail={signUpEmail} giftCardGiveaway={giftCardGiveaway} /> : 
                <div>
                    {!giftCardGiveaway ?
                        <div>
                            <Oauth title="Quick Sign Up" description="To continue, sign up with Cashback Champ using one of these providers:" 
                                useFacebook={true} useGoogle={true} />
                            <h3 className="mt-2">Sign Up</h3>
                            <p>Want to create a separate Cashback Champ login? No problem. Just enter the information below and you're in! All fields are required.</p>
                        </div>
                    : 
                        ""
                    }
                    { signUpError !== "" ? <p><font color="red">{Parser(signUpError)}</font></p> : '' }
                    <Form>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Group controlId="signUpFirstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control autoFocus type="text" placeholder="First Name" onChange={e => setSignUpFirstName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="signUpLastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" onChange={e => setSignUpLastName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="signUpEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" onChange={e => setSignUpEmail(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="signUpPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" onChange={e => setSignUpPassword(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="signUpConfirmPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" placeholder="Confirm Password" onChange={e => setSignUpConfirmPassword(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                        
                        <Button className="center" variant="primary" type="button" onClick={signUp}>
                            Sign Up
                        </Button>
                    </Form>
                </div>
            }
        </div>
    );
};