import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faUserPlus, faSearch, faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";

const Styles = styled.div`
    .detailedHeader {
        text-align: center;
        font-size: 30px;
        font-family: "Open Sans",trebuchet ms;
        font-weight: 900;
    }

    hr {
        margin-top: 10px;
        margin-bottom: 10px;
        border: 0;
        border-top: 4px solid #339966;
        width: 50%;
      }

    .subhead {
        font-style: italic;
        text-align: center;
        font-size: 16px;
        font-family: "Open Sans",trebuchet ms;
    }

    .textCenter {
        text-align: center;
    }

    .circle-icon {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        text-align: center;
        line-height: 100px;
        vertical-align: middle;
        padding: 30px;
        background: #71AB72;
        color: #FFFFFF;
    }

    .iconHead {
        font-size: 28px;
        font-family: "Open Sans",trebuchet ms;
        font-weight: 900;
        color: #71AB72;
        margin: 10px 0 10px 0;
    }

    .iconBody {
        font-size: 16px;
        font-family: "Open Sans",trebuchet ms;
        line-height: 28px;
    }

    .spacing {
        margin-top: 30px;
    }
`;

export const HowCashbackWorks = () => (
    <Styles>
        <Helmet>
            <title>How Cashback Works at CashbackChamp.com</title>
            <meta name="description" content="Let us explain how you can earn cash back 100% for free from over 500 merchants exclusively from CashbackChamp.com." />
        </Helmet>
        <div>
            <h1>How Cashback Works</h1>
            <br /><br />
            <div className="detailedHeader">The Detailed Cashback Champ Process</div>
            <hr />
            <div className="subhead">How We Work to Put the Most Money in Your Pocket</div>
            
            <Container className="spacing">
                <Row>
                    <Col className="textCenter">
                        <FontAwesomeIcon icon={faUserPlus} className="circle-icon" />
                        <div className="iconHead">Signup</div>
                        <div className="iconBody">
                            Users sign up to Cashback Champ using a Facebook or GMail account. 
                            Or, join directly with an email address.
                        </div>
                    </Col>
                    <Col className="textCenter">
                        <FontAwesomeIcon icon={faSearch} className="circle-icon" />
                        <div className="iconHead">Browse</div>
                        <div className="iconBody">
                            Browse 1000s of stores through Cashback Champ. Click on a Shop Now 
                            link to be redirected to the merchant's webiste.
                        </div>
                    </Col>
                    <Col className="textCenter">
                        <FontAwesomeIcon icon={faShoppingCart} className="circle-icon" />
                        <div className="iconHead">Purchase</div>
                        <div className="iconBody">
                            Immediately after clicking on the Shop Now link, browse the merchant's 
                            website and complete your purchase as normal.
                        </div>
                    </Col>
                    <Col className="textCenter">
                        <FontAwesomeIcon icon={faPiggyBank} className="circle-icon" />
                        <div className="iconHead">Earn</div>
                        <div className="iconBody">
                            We are notified by the merchant of your purchase and of our commission. 
                            We then share a portion of this commission with you.
                        </div>
                    </Col>
                    <Col className="textCenter">
                        <FontAwesomeIcon icon={faPaypal} className="circle-icon" />
                        <div className="iconHead">Collect</div>
                        <div className="iconBody">
                            Every quarter, we deliver your earnings as a deposit into your Paypal account. 
                            Other payment options coming soon.
                        </div>
                    </Col>
                </Row>
            </Container>
            
            <div className="spacing">
                <div>
                    <p>
                        Simply put, we earn commission from our merchants and we share it with you.
                    </p>

                    <p>
                        The process is really just 3 easy steps.

                        <ol>
                            <li>
                                Sign up with Cashback Champ. You can use your Gmail or Facebook account. 
                                Or, register directly with our site using your email address.
                            </li>
                            <li>
                                Next, search our website for a store or the type of product you’d like to purchase. 
                                When you find a store, just click on their Shop Now button from our website. 
                                We will redirect you to the store’s website.
                            </li>
                            <li>
                                Immediately after clicking the Shop Now link on our website, browse the merchant’s site and make your purchase. 
                                Within 24 hours, you will see the savings added directly to your Cashback Champ account. 
                                Congratulations! You just earned cashback!
                            </li>
                        </ol>
                    </p>

                    <p>
                        It’s really that easy. Plus, it’s 100% free.
                    </p>
                    <p>
                        The last step is to register your PayPal account with Cashback Champ. Then, every quarter, we deposit 
                        your cashback straight into your PayPal account and send you a nice little email to congratulate you 
                        on all that cash you just earned.
                    </p>

                    <p className="textCenter">
                        Ready to Sign Up? Click the Sign Up button in the main menu to join today!
                    </p>

                    <p className="textCenter">
                        Still not convinced or have more questions? Check out our <Link to="/help">Help section (FAQ)</Link>.
                    </p>
                </div>
            </div>
        </div>           
    </Styles>
)