import {Helmet} from "react-helmet";

export const Error404 = () => (
    <div>
        <Helmet>
            <title>Page Not Found - CashbackChamp.com</title>
            <meta name="description" content="Sorry, but the page you requested cannot be found." />
        </Helmet>
        <h1>404 Error</h1>
        <p>The page is not found.</p>
    </div>
)