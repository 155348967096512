import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';

const GLOBAL_PAYPAL_FUNCTION = process.env.REACT_APP_PAYPAL_FUNCTION;

export const PaypalConfirm = (props) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('code')) {
            let code = params.get('code');
            let userName = null;
            Auth.currentAuthenticatedUser().then((user) => {
                userName = user.username;

                if (code && userName) {
                    console.log("Code: ", code);
                    console.log("Username: ", userName);
                    
                    Auth.currentCredentials().then(credentials => {
                        const lambda = new Lambda({
                            credentials: Auth.essentialCredentials(credentials),
                            region: "us-east-1"
                        });
                        lambda.invoke({
                            FunctionName: GLOBAL_PAYPAL_FUNCTION,
                            Payload: JSON.stringify({ "code" : code, "user" : userName }),
                        }, function(err, data) {
                            if (err) {
                                console.log("Calling Lambda Function Error: ");
                                console.log(err, err.stack); // an error occurred
                                console.log("Data Log: ", data.LogResult);
                            } else {
                                console.log("Calling Lambda Function Success: ");
                                console.log(data.Payload);           // successful response
                                window.opener.postMessage("paypalemail:" + data.Payload.replaceAll("\"", ""), "*");
                                window.close();
                            }
                        });
                    });
                }
            }).catch(() => {
                console.log("User not logged in.");
            });
        }
    }, []);

    return (
        <Container>
            Please wait while we pull your Paypal details...
        </Container>
    );
}