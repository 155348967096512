import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Button, Modal } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import { API, graphqlOperation } from 'aws-amplify';
import { createClickTracker } from './graphql/mutations';
import styled from 'styled-components';
import { useGlobalState } from 'state-pool';

const Styles = styled.div`
    .store {
        margin-bottom: 10px;
    }

    .storeItem {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media only screen and (max-width: 600px) {
        .cbcDeal {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            margin: 5px;
        }

        .buttonAlignment {
            text-align: center;
            margin-bottom: 15px;
        }
    }

    @media only screen and (min-width: 601px) {
        .cbcDeal {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            margin: 30px;
            padding: 20px;
        }

        .buttonAlignment {
            text-align: right;
        }
    }
    
    .paddingTop10 {
        padding-top: 15px;
    }
`;

export const Store = (props) => {
    const [storesList, setStoresList] = useGlobalState("storesList");
    const {currentUser} = props;
    const [storeObject, setStoreObject] = useState(null);
    const [showTrackerModal, setShowTrackerModal] = useState(false);
    const [clickTrackerId, setClickTrackerId] = useState(null);
    const [timer, setTimer] = useState(5);
    const { id } = useParams();

    useEffect(() => {
        if (id && storesList) {
            let s = storesList.filter(w => w.id === id);
            if (s) {
                setStoreObject(s[0]);
            }
        }
      }, [id, storesList]);

    const trackClick = (commission) => {
        if (commission && storeObject) {
            const input = {
                cbcMerchantId: storeObject.id,
                commissionClicked: commission.id, 
                dateCreated: new Date().toISOString(), 
                ipAddress: "127.0.0.1", 
                referrer: window.location.href,
                userAgent: navigator.userAgent, 
                userId: currentUser.username, 
                allCommissions: JSON.stringify(storeObject.commissions.items)
            }
            API.graphql(graphqlOperation(createClickTracker, {input})).then((click) => {
                setClickTrackerId(click.data.createClickTracker.id);
                setShowTrackerModal(true);
                setTimeout(updateTimer, 1000, 5);
                setTimeout(openInNewTab, 5000, storeObject.url.replace("?sid=123", "?sid=" + click.data.createClickTracker.id));
            });
        }
    }

    const updateTimer = (start) => {
        let t = start;
        if (t > 0) {
            t = t - 1;
        }
        setTimer(t);
        if (t > 0) {
            setTimeout(updateTimer, 1000, t);
        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    const closeAdModal = () => {
        setShowTrackerModal(false);
    }

    const renderContent = () => {
        if (storeObject !== null && storeObject) {
            let storeCommissions = <p>No deals currently for this store. Try back later.</p>;
            if (storeObject.commissions && storeObject.commissions.items && storeObject.commissions.items.length > 0) {
                storeCommissions = storeObject.commissions.items.map((c) =>
                    (c.isCommissionUsed) ?
                        <div key={c.id}>
                            <Row className="align-items-center cbcDeal">
                                <Col xs="12" sm="12" md={currentUser ? 10 : 8} className="paddingTop10">
                                    <h3>{c.title}</h3>
                                    <p>{c.description}</p>
                                </Col> 
                                <Col xs="12" sm="12" md={currentUser ? 2 : 4} className="buttonAlignment">
                                    <Button variant="success" onClick={currentUser ? e => trackClick(c) : props.modalOpenEvent}>{currentUser ? "Save " + (c.commissionType === "PERCENT" ? c.commission + "%" : "$" + c.commission) : "Signup to Save"}</Button>
                                </Col>
                            </Row>
                            <hr />
                        </div>
                    : 
                        ""
                );
            }
                
            return (
                <div>
                    <Helmet>
                        <title>Earn Cash Back from {storeObject.name} - CashbackChamp.com</title>
                        <meta name="description" content={"Do you like to shop at " + storeObject.name + "? If so, come to CashbackChamp.com and earn cash back for every purchase you make. Plus, it's 100% free!"} />
                    </Helmet>
                    <Container>
                        <Row>
                            <Col>
                                <Image src={"https://cbc-images-staging215950-staging.s3.amazonaws.com/public/" + storeObject.id.replace("CJ-", "") + ".gif"} fluid />
                                <p>
                                    {storeObject.shortDescription}
                                </p>

                            </Col>
                            <Col xs={12} sm={12} md={9}>
                                <h1>{storeObject.headingTitle}</h1>
                                <p>
                                    {storeObject.description}
                                </p>
                                
                                <hr />
                                <h2>Current {storeObject.name} Deals</h2>
                                {storeCommissions}
                            </Col>
                        </Row>

                        <Modal animation={false} size="lg" centered show={showTrackerModal} onHide={closeAdModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Savings are On The Way!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="text-center">
                                    <Col>
                                        <Image className='trackerImage' src={"https://cbc-images-staging215950-staging.s3.amazonaws.com/public/" + storeObject.id.replace("CJ-", "") + ".gif"} />
                                    </Col>
                                </Row>
                                <Row className="text-center">
                                    <Col>
                                        <p>You are now ready to earn on your orders at {storeObject.name}.</p>
                                        <p>This shopping trip is tracked. This means you will get cashback if you make a purchase at {storeObject.name} during this session (don't close your browser or navigate to another webpage while shopping).</p>
                                        <p>Your shopping trip # is {clickTrackerId}.</p>
                                        <p>Don't worry. You don't need to remember it. You can always find all of your shopping trips in the My Trips area of your account.</p>
                                        <p>If you are not redirected to {storeObject.name} in a few seconds, please <Button className="noButton" variant="link" onClick={e => openInNewTab(storeObject.url.replace("?sid=123", "?sid=" + clickTrackerId))}>click here</Button>.</p>
                                        <p>A new browser tab or window will open and send you to the {storeObject.name} website in {timer} seconds.</p>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={closeAdModal}>
                                Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Container>
                </div>
            )
        } else {
            return (
                <div>Please wait....loading store.</div>
            )
        }
    }

    return (
        <Styles>
            { renderContent() }
        </Styles>
    );
}