import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
//import { searchCbcMerchants } from './graphql/queries';
import styled from 'styled-components';

const Styles = styled.div``;

export const SearchResults = (props) => {
	const { term } = useParams();
	const [results, setResults] = useState(null);

	useEffect(() => {
		if (term && term !== '') {
			let wordArray = term.split(' ');
			let filter = {};
			if (wordArray.length === 1) {
				// Only 1 word
				filter = {
					filter: {
						name: {
							wildcard: '*' + wordArray[0].toLowerCase() + '*',
						},
					},
				};
			} else {
				// Multiple words
				for (let i = 0; i < wordArray.length; i++) {
					if (i === 0) {
						// First word
						filter = {
							filter: {
								name: {
									wildcard: '*' + wordArray[0].toLowerCase() + '*',
								},
							},
						};
					} else {
						switch (i) {
							case 1:
								filter.filter.or = {
									name: {
										wildcard: '*' + wordArray[i].toLowerCase() + '*',
									},
								};
								break;
							case 2:
								filter.filter.or.or = {
									name: {
										wildcard: '*' + wordArray[i].toLowerCase() + '*',
									},
								};
								break;
							case 3:
								filter.filter.or.or.or = {
									name: {
										wildcard: '*' + wordArray[i].toLowerCase() + '*',
									},
								};
								break;
							case 4:
								filter.filter.or.or.or.or = {
									name: {
										wildcard: '*' + wordArray[i].toLowerCase() + '*',
									},
								};
								break;
							case 5:
								filter.filter.or.or.or.or.or = {
									name: {
										wildcard: '*' + wordArray[i].toLowerCase() + '*',
									},
								};
								break;
							default:
						}
					}
				}
			}

			// API.graphql(graphqlOperation(searchCbcMerchants, filter)).then((r) => {
			//     setResults(r.data.searchCbcMerchants.items);
			// }).catch((error) => {
			//     console.log(error);
			// });
		} else {
			setResults('');
		}
	}, [term]);

	const renderContent = () => {
		if (results !== null) {
			if (results === '') {
				return (
					<Container>
						<Row>
							<Col>
								<h1>Search Results</h1>
								<p>There are no stores matching your search term: {term}. Please try again.</p>
							</Col>
						</Row>
					</Container>
				);
			} else {
				const sResults = results.map((store) => (
					<Col key={store.id} className="storeItem">
						<Link to={'/store/' + store.id}>
							<Image
								src={
									'https://cashbackchamp-api-prod-storage-d2n49jw1g0fh57203816-staging.s3.amazonaws.com/' +
									store.id.replace('CJ-', '') +
									'.gif'
								}
								fluid
							/>
						</Link>
					</Col>
				));

				return (
					<Container>
						<Row>
							<Col>
								<h1>Search Results</h1>
								<p>
									Below are the store search results for your search term:{' '}
									<strong>
										<em>{term}</em>
									</strong>
								</p>
								<hr />
								<br />
								<Row xs={4} className="align-items-center">
									{sResults}
								</Row>
							</Col>
						</Row>
					</Container>
				);
			}
		} else {
			return <div></div>;
		}
	};

	return <Styles>{renderContent()}</Styles>;
};
