/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCbcMerchantReviewQueue = /* GraphQL */ `
  mutation CreateCbcMerchantReviewQueue(
    $input: CreateCbcMerchantReviewQueueInput!
    $condition: ModelCbcMerchantReviewQueueConditionInput
  ) {
    createCbcMerchantReviewQueue(input: $input, condition: $condition) {
      id
      merchantId
      isActive
      actionTermCount
      actionTermSummary
      name
      url
      categoryName
      childCategoryName
      reason
      isReviewed
      reviewedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateCbcMerchantReviewQueue = /* GraphQL */ `
  mutation UpdateCbcMerchantReviewQueue(
    $input: UpdateCbcMerchantReviewQueueInput!
    $condition: ModelCbcMerchantReviewQueueConditionInput
  ) {
    updateCbcMerchantReviewQueue(input: $input, condition: $condition) {
      id
      merchantId
      isActive
      actionTermCount
      actionTermSummary
      name
      url
      categoryName
      childCategoryName
      reason
      isReviewed
      reviewedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCbcMerchantReviewQueue = /* GraphQL */ `
  mutation DeleteCbcMerchantReviewQueue(
    $input: DeleteCbcMerchantReviewQueueInput!
    $condition: ModelCbcMerchantReviewQueueConditionInput
  ) {
    deleteCbcMerchantReviewQueue(input: $input, condition: $condition) {
      id
      merchantId
      isActive
      actionTermCount
      actionTermSummary
      name
      url
      categoryName
      childCategoryName
      reason
      isReviewed
      reviewedBy
      createdAt
      updatedAt
    }
  }
`;
export const createCbcMerchant = /* GraphQL */ `
  mutation CreateCbcMerchant(
    $input: CreateCbcMerchantInput!
    $condition: ModelCbcMerchantConditionInput
  ) {
    createCbcMerchant(input: $input, condition: $condition) {
      id
      name
      categoryName
      childCategoryName
      headingTitle
      shortDescription
      description
      url
      cbcUrl
      cbcCategoryName
      cbcChildCategoryName
      featureStoreId
      isMerchantUsed
      isActive
      commissions {
        items {
          id
          cbcMerchantId
          merchantName
          actionTrackerId
          commission
          isPercent
          title
          description
          isCommissionUsed
          referralPeriod
          referralOccurrences
          commissionCurrency
          commissionType
          commissionValue
          actionTrackerType
          lockingMethodType
          lockingMethodDurationInDays
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCbcMerchant = /* GraphQL */ `
  mutation UpdateCbcMerchant(
    $input: UpdateCbcMerchantInput!
    $condition: ModelCbcMerchantConditionInput
  ) {
    updateCbcMerchant(input: $input, condition: $condition) {
      id
      name
      categoryName
      childCategoryName
      headingTitle
      shortDescription
      description
      url
      cbcUrl
      cbcCategoryName
      cbcChildCategoryName
      featureStoreId
      isMerchantUsed
      isActive
      commissions {
        items {
          id
          cbcMerchantId
          merchantName
          actionTrackerId
          commission
          isPercent
          title
          description
          isCommissionUsed
          referralPeriod
          referralOccurrences
          commissionCurrency
          commissionType
          commissionValue
          actionTrackerType
          lockingMethodType
          lockingMethodDurationInDays
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCbcMerchant = /* GraphQL */ `
  mutation DeleteCbcMerchant(
    $input: DeleteCbcMerchantInput!
    $condition: ModelCbcMerchantConditionInput
  ) {
    deleteCbcMerchant(input: $input, condition: $condition) {
      id
      name
      categoryName
      childCategoryName
      headingTitle
      shortDescription
      description
      url
      cbcUrl
      cbcCategoryName
      cbcChildCategoryName
      featureStoreId
      isMerchantUsed
      isActive
      commissions {
        items {
          id
          cbcMerchantId
          merchantName
          actionTrackerId
          commission
          isPercent
          title
          description
          isCommissionUsed
          referralPeriod
          referralOccurrences
          commissionCurrency
          commissionType
          commissionValue
          actionTrackerType
          lockingMethodType
          lockingMethodDurationInDays
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCommission = /* GraphQL */ `
  mutation CreateCommission(
    $input: CreateCommissionInput!
    $condition: ModelCommissionConditionInput
  ) {
    createCommission(input: $input, condition: $condition) {
      id
      cbcMerchantId
      merchantName
      actionTrackerId
      commission
      isPercent
      title
      description
      isCommissionUsed
      referralPeriod
      referralOccurrences
      commissionCurrency
      commissionType
      commissionValue
      actionTrackerType
      lockingMethodType
      lockingMethodDurationInDays
      createdAt
      updatedAt
    }
  }
`;
export const updateCommission = /* GraphQL */ `
  mutation UpdateCommission(
    $input: UpdateCommissionInput!
    $condition: ModelCommissionConditionInput
  ) {
    updateCommission(input: $input, condition: $condition) {
      id
      cbcMerchantId
      merchantName
      actionTrackerId
      commission
      isPercent
      title
      description
      isCommissionUsed
      referralPeriod
      referralOccurrences
      commissionCurrency
      commissionType
      commissionValue
      actionTrackerType
      lockingMethodType
      lockingMethodDurationInDays
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommission = /* GraphQL */ `
  mutation DeleteCommission(
    $input: DeleteCommissionInput!
    $condition: ModelCommissionConditionInput
  ) {
    deleteCommission(input: $input, condition: $condition) {
      id
      cbcMerchantId
      merchantName
      actionTrackerId
      commission
      isPercent
      title
      description
      isCommissionUsed
      referralPeriod
      referralOccurrences
      commissionCurrency
      commissionType
      commissionValue
      actionTrackerType
      lockingMethodType
      lockingMethodDurationInDays
      createdAt
      updatedAt
    }
  }
`;
export const createClickTracker = /* GraphQL */ `
  mutation CreateClickTracker(
    $input: CreateClickTrackerInput!
    $condition: ModelClickTrackerConditionInput
  ) {
    createClickTracker(input: $input, condition: $condition) {
      id
      userId
      cbcMerchantId
      dateCreated
      userAgent
      referrer
      ipAddress
      commissionClicked
      allCommissions
      commissionIdReturned
      totalCommissionEarned
      cbcCommission
      userCommission
      dateEarned
      affiliateCommissionResponse
      userPayoutId
      datePaidOut
      returnedActionTrackerId
      lockingDate
      lockingMethod
      merchant {
        id
        name
        categoryName
        childCategoryName
        headingTitle
        shortDescription
        description
        url
        cbcUrl
        cbcCategoryName
        cbcChildCategoryName
        featureStoreId
        isMerchantUsed
        isActive
        commissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateClickTracker = /* GraphQL */ `
  mutation UpdateClickTracker(
    $input: UpdateClickTrackerInput!
    $condition: ModelClickTrackerConditionInput
  ) {
    updateClickTracker(input: $input, condition: $condition) {
      id
      userId
      cbcMerchantId
      dateCreated
      userAgent
      referrer
      ipAddress
      commissionClicked
      allCommissions
      commissionIdReturned
      totalCommissionEarned
      cbcCommission
      userCommission
      dateEarned
      affiliateCommissionResponse
      userPayoutId
      datePaidOut
      returnedActionTrackerId
      lockingDate
      lockingMethod
      merchant {
        id
        name
        categoryName
        childCategoryName
        headingTitle
        shortDescription
        description
        url
        cbcUrl
        cbcCategoryName
        cbcChildCategoryName
        featureStoreId
        isMerchantUsed
        isActive
        commissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteClickTracker = /* GraphQL */ `
  mutation DeleteClickTracker(
    $input: DeleteClickTrackerInput!
    $condition: ModelClickTrackerConditionInput
  ) {
    deleteClickTracker(input: $input, condition: $condition) {
      id
      userId
      cbcMerchantId
      dateCreated
      userAgent
      referrer
      ipAddress
      commissionClicked
      allCommissions
      commissionIdReturned
      totalCommissionEarned
      cbcCommission
      userCommission
      dateEarned
      affiliateCommissionResponse
      userPayoutId
      datePaidOut
      returnedActionTrackerId
      lockingDate
      lockingMethod
      merchant {
        id
        name
        categoryName
        childCategoryName
        headingTitle
        shortDescription
        description
        url
        cbcUrl
        cbcCategoryName
        cbcChildCategoryName
        featureStoreId
        isMerchantUsed
        isActive
        commissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPayout = /* GraphQL */ `
  mutation CreatePayout(
    $input: CreatePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    createPayout(input: $input, condition: $condition) {
      id
      name
      startDate
      finishDate
      userPayouts {
        items {
          id
          payoutId
          userId
          paymentAmount
          paypalId
          paypalEmail
          dateCreated
          status
          datePaid
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePayout = /* GraphQL */ `
  mutation UpdatePayout(
    $input: UpdatePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    updatePayout(input: $input, condition: $condition) {
      id
      name
      startDate
      finishDate
      userPayouts {
        items {
          id
          payoutId
          userId
          paymentAmount
          paypalId
          paypalEmail
          dateCreated
          status
          datePaid
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePayout = /* GraphQL */ `
  mutation DeletePayout(
    $input: DeletePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    deletePayout(input: $input, condition: $condition) {
      id
      name
      startDate
      finishDate
      userPayouts {
        items {
          id
          payoutId
          userId
          paymentAmount
          paypalId
          paypalEmail
          dateCreated
          status
          datePaid
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserPayout = /* GraphQL */ `
  mutation CreateUserPayout(
    $input: CreateUserPayoutInput!
    $condition: ModelUserPayoutConditionInput
  ) {
    createUserPayout(input: $input, condition: $condition) {
      id
      payoutId
      userId
      paymentAmount
      paypalId
      paypalEmail
      dateCreated
      status
      datePaid
      payout {
        id
        name
        startDate
        finishDate
        userPayouts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserPayout = /* GraphQL */ `
  mutation UpdateUserPayout(
    $input: UpdateUserPayoutInput!
    $condition: ModelUserPayoutConditionInput
  ) {
    updateUserPayout(input: $input, condition: $condition) {
      id
      payoutId
      userId
      paymentAmount
      paypalId
      paypalEmail
      dateCreated
      status
      datePaid
      payout {
        id
        name
        startDate
        finishDate
        userPayouts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserPayout = /* GraphQL */ `
  mutation DeleteUserPayout(
    $input: DeleteUserPayoutInput!
    $condition: ModelUserPayoutConditionInput
  ) {
    deleteUserPayout(input: $input, condition: $condition) {
      id
      payoutId
      userId
      paymentAmount
      paypalId
      paypalEmail
      dateCreated
      status
      datePaid
      payout {
        id
        name
        startDate
        finishDate
        userPayouts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLastCommissionId = /* GraphQL */ `
  mutation CreateLastCommissionId(
    $input: CreateLastCommissionIdInput!
    $condition: ModelLastCommissionIdConditionInput
  ) {
    createLastCommissionId(input: $input, condition: $condition) {
      id
      commissionId
      createdAt
      updatedAt
    }
  }
`;
export const updateLastCommissionId = /* GraphQL */ `
  mutation UpdateLastCommissionId(
    $input: UpdateLastCommissionIdInput!
    $condition: ModelLastCommissionIdConditionInput
  ) {
    updateLastCommissionId(input: $input, condition: $condition) {
      id
      commissionId
      createdAt
      updatedAt
    }
  }
`;
export const deleteLastCommissionId = /* GraphQL */ `
  mutation DeleteLastCommissionId(
    $input: DeleteLastCommissionIdInput!
    $condition: ModelLastCommissionIdConditionInput
  ) {
    deleteLastCommissionId(input: $input, condition: $condition) {
      id
      commissionId
      createdAt
      updatedAt
    }
  }
`;
