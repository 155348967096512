import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Parser from 'html-react-parser';
import styled from 'styled-components';
import { ForgotPassword } from '../components/ForgotPassword';
import { VerifyForm } from '../components/VerifyForm';
import AuthHelper from '../components/AuthHelper';
import {Helmet} from "react-helmet";

const GLOBAL_PAYPAL_APP_ID = process.env.REACT_APP_PAYPAL_APP_ID;
const GLOBAL_PAYPAL_REDIRECT_URL = process.env.REACT_APP_PAYPAL_REDIRECT_URL;

const Styles = styled.div`
    .noLeftPad {
        padding-left: 0px !important;
    }

    .spacer {
        margin-bottom: 60px;
    }
`;

export const Profile = (props) => {
    const {getLoggedInState} = props;
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [email, setEmail] = useState("");
    const [originalEmail, setOriginalEmail] = useState("");
    const [profileError, setProfileError] = useState('');
    const [currentUser, setCurrentUser] = useState(props.currentUser);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [canChangeEmail, setCanChangeEmail] = useState(true);
    const [showVerify, setShowVerify] = useState(false);
    const [paypalEmail, setPaypalEmail] = useState("");

    useEffect(() => {
        async function fetchUser() {
            if (currentUser === null) {
                setCurrentUser(await Auth.currentAuthenticatedUser({bypassCache: true}));    
            }
            
            if (currentUser.attributes["custom:given_name_overwrite"]) {
                setGivenName(currentUser.attributes["custom:given_name_overwrite"]);
            } else {
                setGivenName(currentUser.attributes.given_name);
            }
            if (currentUser.attributes["custom:last_name_overwrite"]) {
                setFamilyName(currentUser.attributes["custom:last_name_overwrite"]);
            } else {
                setFamilyName(currentUser.attributes.family_name);
            }
            if (currentUser.attributes["custom:email_overwrite"]) {
                setEmail(currentUser.attributes["custom:email_overwrite"]);
                setOriginalEmail(currentUser.attributes["custom:email_overwrite"]);
            } else {
                setEmail(currentUser.attributes.email);
                setOriginalEmail(currentUser.attributes.email);
            }
            if (currentUser.attributes.identities && currentUser.attributes.identities.length > 0) {
                setCanChangeEmail(false);
            }

            let user1 = await AuthHelper.refreshCurrentSession();

            if (user1.idToken && user1.idToken.payload["custom:paypal_account"]) {
                setPaypalEmail(user1.idToken.payload["custom:paypal_account"]);
            }
        }

        fetchUser().then((result) => {
            drawPaypalButton();
        });
    }, [currentUser]);

    window.addEventListener("message", (event) => {
        if (typeof event.data === 'string' || event.data instanceof String) {
            if (event.data.includes("paypalemail:")) {
                let mParts = event.data.split(":");
                if (mParts.length > 1) {
                    setPaypalEmail(mParts[1]);
                }
            }
        }
    }, false);

    const drawPaypalButton = () => {
        window.paypal.use( ['login'], function (login) {
            if (window.location.host === "www.cashbackchamp.com") {
                // Production
                login.render ({
                    "appid": GLOBAL_PAYPAL_APP_ID,
                    "scopes": "openid profile email https://uri.paypal.com/services/paypalattributes",
                    "containerid": "cwppButton",
                    "responseType": "code",
                    "locale": "en-us",
                    "buttonType": "CWP",
                    "buttonShape": "pill",
                    "buttonSize": "md",
                    "fullPage": "false",
                    "returnurl": GLOBAL_PAYPAL_REDIRECT_URL
                });
            } else {
                // Staging
                login.render ({
                    "appid": GLOBAL_PAYPAL_APP_ID,
                    "authend": "sandbox",
                    "scopes": "openid profile email https://uri.paypal.com/services/paypalattributes",
                    "containerid": "cwppButton",
                    "responseType": "code",
                    "locale": "en-us",
                    "buttonType": "CWP",
                    "buttonShape": "pill",
                    "buttonSize": "md",
                    "fullPage": "false",
                    "returnurl": GLOBAL_PAYPAL_REDIRECT_URL
                });
            }
        });
    }

    const updateProfile = async(props) => {
        let error = "";
        if (givenName === "") {
            error += "First Name is required. Please enter your first name.<br />";
        }

        if (familyName === "") {
            error += "Last Name is required. Please enter your last name.<br />";
        }

        if (error !== "") {
            setProfileError(error);
        } else {
            try {
                let status = "CONFIRMED"; 
                if (originalEmail !== email) {
                    status = "UNCONFIRMED";
                }
                let result = await Auth.updateUserAttributes(currentUser, {
                    'given_name': givenName,
                    'custom:given_name_overwrite': givenName,
                    'family_name': familyName,
                    'custom:last_name_overwrite': familyName,
                    'email': email,
                    'custom:email_overwrite':  email,
                    'custom:user_status': status
                });
                if (result === "SUCCESS") {
                    if (status === "UNCONFIRMED") {
                        //await Auth.signOut();
                        getLoggedInState();
                        setShowVerify(true);
                    } else {
                        getLoggedInState();
                        setProfileError("We have successfully updated your profile.");
                    }
                } else {
                    setProfileError(error.message + ". Please try again.");
                }
            } catch(error) {
                setProfileError(error.message + ". Please try again.");
            }
        }
    }

    const renderContent = () => {
        if (currentUser === null) {
            return (
                <div>
                    <h2>No User Found</h2>
                    You must be signed in to view this page. Please click the Signup / Login link in the main menu.
                </div>
            );
        } else {
            if (showResetPassword) {
                return <ForgotPassword title="Change Password" submitButtonText="Change Password" isProfile="true" setShowResetPassword={setShowResetPassword} />
            } else {
                if (showVerify) {
                    return <VerifyForm initialEmail={email} setShowVerify={setShowVerify} setProfileError={setProfileError} />
                } else {
                    return (
                        <div>
                            <h2>Update Your Profile</h2>
                            <p>You can change your profile information below.</p>
                            { profileError !== "" ? <p><font color="red">{Parser(profileError)}</font></p> : '' }

                            <Form>
                                <Form.Group controlId="profileGivenName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="First Name" value={givenName} onChange={e => setGivenName(e.target.value)} />
                                </Form.Group>

                                <Form.Group controlId="profileFamilyName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Last Name" value={familyName} onChange={e => setFamilyName(e.target.value)} />
                                </Form.Group>

                                { canChangeEmail ?
                                    <Form.Group controlId="profileEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                        <Form.Text className="text-muted">
                                            Note: If you change your email address, you will be emailed a new verification code to your new 
                                            email address and will be required to verify it before you can continue to use your account.
                                        </Form.Text>
                                    </Form.Group>
                                : 
                                    <Form.Group controlId="profileEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" placeholder="Email" value={email} readOnly disabled />
                                        <Form.Text className="text-muted">
                                            Your email comes from your social media account, so it is not editable.
                                        </Form.Text>
                                    </Form.Group>
                                }
                                
                                <Form.Group controlId="profileResetPassword">
                                    <Button className="noLeftPad" variant="link" type="button" onClick={e => setShowResetPassword(true)}>Change Password</Button>
                                </Form.Group>

                                <Button variant="primary" type="button" onClick={e => updateProfile(props)}>
                                    Update Profile
                                </Button>

                                <div className="spacer"></div>

                                <h4>Get Paid with PayPal</h4>
                                <p>To get your Cashback Champ earnings, you must have a PayPal account and connect it to Cashback Champ. 
                                    Please click the PayPal button to connect your account.</p>
                                
                                { paypalEmail !== "" ?
                                    <Form.Group controlId="paypalEmail">
                                        <Form.Label>Current Paypal Email</Form.Label>
                                        <Form.Control type="text" value={paypalEmail} readOnly disabled />
                                    </Form.Group>
                                :
                                    <div></div>
                                }
                            </Form>
                            
                            <div>
                                <span id='cwppButton'></span>
                                <div className="spacer"><br /></div>
                                <div className="spacer"><br /></div>
                            </div>
                        </div>
                    );
                }
            }
        }
    }

    return (
        <Styles>
            <Helmet>
                <title>My Profile - CashbackChamp.com</title>
                <meta name="description" content="Modify your account information here." />
            </Helmet>
            { renderContent() }
        </Styles>
    );
}