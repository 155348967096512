import { Accordion, Card } from 'react-bootstrap'
import styled from 'styled-components';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

const Styles = styled.div`
    .faqHeader {
        cursor: pointer;
        color: #71AB72;
        font-weight: 900;
    }
`;

export const Help = () => (
    <Styles>
        <Helmet>
            <title>Help & Frequently Asked Questions - CashbackChamp.com</title>
            <meta name="description" content="If you have questions or need help with CashbackChamp.com, then you've come to the right place. Get your questions answered here." />
        </Helmet>
        <div>
            <h1>Help</h1>
            <p>Below is a list of Frequently Asked Questions (FAQs) that we often get about CashbackChamp. Please click on a question below to see the answer.</p>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="faqHeader">
                        How do I use a coupon code?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <p>
                                Cashback Champ offers thousands of coupons from your favorites stores and retailers. 
                                To use a retailer’s coupon code, just click on the Reveal Coupon button on our site. 
                                This will open a new tab in your browser directing you to the merchant’s site. 
                                It will also pop up a small window that will show you the coupon code. 
                                The coupon code is also copied to your computer’s clipboard automatically.
                            </p>
                            <p>
                                Now, go to the merchant’s website and shop. When you get to the shopping cart, you will 
                                see a field called Discount Code, Coupon Code or something similar. Paste the coupon code you 
                                received from Cashback Champ and save immediately on your purchase.
                            </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1" className="faqHeader">
                        How do I sign up for Cashback Champ?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <p>
                                Signing up for Cashback Champ is easy. All you need is either an email address, a Facebook account or a Gmail account. 
                                Using a Facebook or Gmail account, our site will first ask you to login to your respective account. 
                                Then, once you login and agree, you will have access to the site. If you instead opt to use your email address, 
                                we will also ask you for your first and last name.
                            </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2" className="faqHeader">
                        How does this cashback process actually work?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <p>
                                We make money every time someone clicks on a store link from this site and purchases a product or service at that merchant. 
                                We are paid either a flat-fee commission or a percentage of the sale by the merchant for each purchase. Then, we share a 
                                portion of your purchase’s commission with you.
                            </p>
                            <p>
                                For a much more detailed description, please read our <Link to="/how-cashback-works">How Cashback Works page</Link>.
                            </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3" className="faqHeader">
                        Why do you offer cashback?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <p>
                                Cashback Champ partners with thousands of stores to bring you ways to save money.  
                                These merchants pay us a commission every time we bring a customer to their site and that 
                                customer makes a valid purchase. We then share this commission with you in the form of cash back.
                            </p>
                            <p>
                                The more people that shop through Cashback Champ, the better commission percentages we receive from 
                                our merchant partners. This then allows us to raise the cashback percentages we can offer our members. 
                                A win-win-win for you, the merchant and us.
                            </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="4" className="faqHeader">
                        What is a shopping trip?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <p>
                                Every time you click on a Reveal Coupon or Shop Now cashback button on our website, we store this information 
                                and provide you with a Shopping Trip ID. This ID is useful to use in case you are ever missing cashback on a valid purchase.
                            </p>
                            <p>
                                In the My Account area of our website, you will see a list of all of your past shopping trips along with their Trip ID number. 
                                If a valid purchase is made during a shopping trip, you will see a green dollar amount in the cashback column.
                            </p>
                            <p>
                                In order to receive cashback, you must have a valid Trip ID. That means you have to click on a link from our website BEFORE 
                                making a purchase at the retailer.
                            </p>
                            <p>
                                If a cash-back-eligible click results in a purchase, the store will notify Cashback Champ about your order usually within 7 days 
                                and your cashback amount will appear next to your Shopping Trip, indicating that you have received Cash Back for your purchase.
                            </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="5" className="faqHeader">
                        Why am I not seeing cashback for a purchase I made?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            <p>
                                Most stores will report their cashback to us within 1-2 days. However, it can take up to 7 days to report 
                                cashback from some stores. If after 7 days you do not see cashback in your Shopping Trips list, please 
                                submit a Missing Cashback Ticket. All Missing Cashback Tickets must be submitted within 60 days of purchase.
                            </p>
                            <p>
                                There is one caveat. With most travel transactions, cashback may not show up in your account until 60 days 
                                after your reservation has been completed.
                            </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="6" className="faqHeader">
                        How can I ensure that I get cashback on all of my purchases?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="6">
                        <Card.Body>
                            <p>
                                While most cashback will be reported to your account from stores in 7 days, sometime issues arise and you 
                                may find that a store did not track your order. Here are a list of things you can do to ensure this doesn’t happen to you.
                            </p>
                            <ol>
                                <li>
                                    Disable all ad blocking and shopping add-ons in your browser. This includes other coupon, cashback, price comparison 
                                    and donation extensions.
                                </li>
                                <li>
                                    If shopping from your iPhone or iPad in Safari, make sure that the merchant’s site does not auto launch the retailer’s app 
                                    on your device instead of staying in the browser. With your device launching an app, the Shopping Trip ID cookie that we 
                                    use to track your purchase will be lost and you will not get credit for your purchase. The easiest way around this 
                                    issue is to delete the retailer app from your device.
                                </li>
                                <li>
                                    Ensure that your device’s browser is able to accept third-party cookies. You can verify that here.
                                </li>
                                <li>
                                    Always begin your Shopping Trips on the Cashback Champ website with an empty cart on the retailers website. 
                                    Shopping on the retailers site and adding items to your cart before clicking on a Cashback Champ Shop Now button 
                                    can result in the merchant not providing cashback.
                                </li>
                                <li>
                                    Once you click a Shop Now button on our website, please make your purchase in a timely manner without browsing other web pages. 
                                    Other sites also make a commission for recommending you to merchants, but they don’t provide cash back. By visiting other websites, 
                                    you could invalidate your Shopping Trip ID cookie.
                                </li>
                                <li>
                                    Do not use gift cards or store credit to pay for your order.
                                </li>
                                <li>
                                    Contacting the merchant to modify your order after purchase will invalidate your cashback. 
                                    Contacting the merchant via phone, email, contact form on merchant’s website or through social media 
                                    will void your cashback. If you need to void your transaction, it is best to cancel your original order 
                                    and then start again on Cashback Champ.
                                </li>
                                <li>
                                    When applying coupons, please be sure to only use the coupons offered by Cashback Champ. Pairing coupons 
                                    from other sites could invalidate your cashback offer.
                                </li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    </Styles>
)