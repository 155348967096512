import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Parser from 'html-react-parser';
import { LoginForm } from './LoginForm';

export const VerifyForm = (props) => {
    const {onSignIn, closeModal, initialEmail, setShowVerify, giftCardGiveaway} = props;
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationEmail, setVerificationEmail] = useState(initialEmail);
    const [message, setMessage] = useState('');
    const [showLoginForm, setShowLoginForm] = useState(false);

    const verifyEmail = async() => {
        try {
            const v = await Auth.confirmSignUp(verificationEmail, verificationCode);
            if (v === "SUCCESS") {
                setShowLoginForm(true);
            } else {
                setMessage('Error confirming sign up. Please try again.');
            }
        } catch(error) {
            if (error.message === "User cannot be confirmed. Current status is CONFIRMED") {
                verifyEmailWithChange();
            } else {
                setMessage(error.message + ". Please try again.");
            }
        }
    }

    const verifyEmailWithChange = async() => {
        try {
            const v = await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode);
            if (v === "SUCCESS") {
                await Auth.currentAuthenticatedUser();
                props.setProfileError("Your email address has been changed and successfully verified.");
                setShowVerify(false);
            } else {
                setMessage('Error verifying email address. Please try again.');
            }
        } catch(error) {
            setMessage(error.message + ". Please try again.");
        }
    }

    const resendCode = async() => {
        if (verificationEmail === "") {
            setMessage("Please enter a valid email address in the Verification Email field.");
        } else {
            try {
                await Auth.resendSignUp(verificationEmail);
                setMessage("Verification code has been resent to " + verificationEmail + ".");
            } catch (err) {
                if (err.message === "User is already confirmed.") {
                    await Auth.verifyCurrentUserAttribute("email");
                    setMessage("Verification code has been resent to " + verificationEmail + ".");
                } else {
                    console.log(err);
                    setMessage(err.message + ". Please try again.");
                }
            }
        }
    }

    return (
        <div>
            {showLoginForm ? 
                <LoginForm onSignIn={onSignIn} closeModal={closeModal}
                    verificationMessage="Your account has been verified. Please now log in with your email address and password."
                    initialEmail={verificationEmail} giftCardGiveaway={giftCardGiveaway} />
            : 
                <div>
                    <h2 className="mt-2">Verify Your Email Address</h2>
                    <p>
                        In order to use CashbackChamp, we must first verify your email address. 
                        Please type you email address in below along with the verification code we just emailed you.
                    </p>
                    { message !== "" ? <p><font color="red">{Parser(message)}</font></p> : '' }
                    <Form>
                        <Form.Group controlId="verificationEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="text" placeholder="Enter Email Here" value={initialEmail} onChange={e => setVerificationEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="verificationCode">
                            <Form.Label>Verification Code</Form.Label>
                            <Button variant="link" onClick={e =>  resendCode()}>Resend Verification Code</Button>
                            <Form.Control autoFocus type="text" placeholder="Enter Code Here" onChange={e => setVerificationCode(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={verifyEmail}>
                            Verify Email Address
                        </Button>
                    </Form>
                </div>
            }
        </div>
    );
}