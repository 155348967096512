/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCbcMerchantReviewQueue = /* GraphQL */ `
  query GetCbcMerchantReviewQueue($id: ID!) {
    getCbcMerchantReviewQueue(id: $id) {
      id
      merchantId
      isActive
      actionTermCount
      actionTermSummary
      name
      url
      categoryName
      childCategoryName
      reason
      isReviewed
      reviewedBy
      createdAt
      updatedAt
    }
  }
`;
export const listCbcMerchantReviewQueues = /* GraphQL */ `
  query ListCbcMerchantReviewQueues(
    $filter: ModelCbcMerchantReviewQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCbcMerchantReviewQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        isActive
        actionTermCount
        actionTermSummary
        name
        url
        categoryName
        childCategoryName
        reason
        isReviewed
        reviewedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCbcMerchantReviewQueueByMerchant = /* GraphQL */ `
  query GetCbcMerchantReviewQueueByMerchant(
    $merchantId: String!
    $isReviewed: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCbcMerchantReviewQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCbcMerchantReviewQueueByMerchant(
      merchantId: $merchantId
      isReviewed: $isReviewed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        isActive
        actionTermCount
        actionTermSummary
        name
        url
        categoryName
        childCategoryName
        reason
        isReviewed
        reviewedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCbcMerchantReviewQueueByIsReviewed = /* GraphQL */ `
  query GetCbcMerchantReviewQueueByIsReviewed(
    $isReviewed: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCbcMerchantReviewQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCbcMerchantReviewQueueByIsReviewed(
      isReviewed: $isReviewed
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        isActive
        actionTermCount
        actionTermSummary
        name
        url
        categoryName
        childCategoryName
        reason
        isReviewed
        reviewedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCbcMerchant = /* GraphQL */ `
  query GetCbcMerchant($id: ID!) {
    getCbcMerchant(id: $id) {
      id
      name
      categoryName
      childCategoryName
      headingTitle
      shortDescription
      description
      url
      cbcUrl
      cbcCategoryName
      cbcChildCategoryName
      featureStoreId
      isMerchantUsed
      isActive
      commissions {
        items {
          id
          cbcMerchantId
          merchantName
          actionTrackerId
          commission
          isPercent
          title
          description
          isCommissionUsed
          referralPeriod
          referralOccurrences
          commissionCurrency
          commissionType
          commissionValue
          actionTrackerType
          lockingMethodType
          lockingMethodDurationInDays
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCbcMerchants = /* GraphQL */ `
  query ListCbcMerchants(
    $filter: ModelCbcMerchantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCbcMerchants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        categoryName
        childCategoryName
        headingTitle
        shortDescription
        description
        url
        cbcUrl
        cbcCategoryName
        cbcChildCategoryName
        featureStoreId
        isMerchantUsed
        isActive
        commissions {
          items {
            id
            cbcMerchantId
            merchantName
            actionTrackerId
            commission
            isPercent
            title
            description
            isCommissionUsed
            referralPeriod
            referralOccurrences
            commissionCurrency
            commissionType
            commissionValue
            actionTrackerType
            lockingMethodType
            lockingMethodDurationInDays
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommission = /* GraphQL */ `
  query GetCommission($id: ID!) {
    getCommission(id: $id) {
      id
      cbcMerchantId
      merchantName
      actionTrackerId
      commission
      isPercent
      title
      description
      isCommissionUsed
      referralPeriod
      referralOccurrences
      commissionCurrency
      commissionType
      commissionValue
      actionTrackerType
      lockingMethodType
      lockingMethodDurationInDays
      createdAt
      updatedAt
    }
  }
`;
export const listCommissions = /* GraphQL */ `
  query ListCommissions(
    $filter: ModelCommissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cbcMerchantId
        merchantName
        actionTrackerId
        commission
        isPercent
        title
        description
        isCommissionUsed
        referralPeriod
        referralOccurrences
        commissionCurrency
        commissionType
        commissionValue
        actionTrackerType
        lockingMethodType
        lockingMethodDurationInDays
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClickTracker = /* GraphQL */ `
  query GetClickTracker($id: ID!) {
    getClickTracker(id: $id) {
      id
      userId
      cbcMerchantId
      dateCreated
      userAgent
      referrer
      ipAddress
      commissionClicked
      allCommissions
      commissionIdReturned
      totalCommissionEarned
      cbcCommission
      userCommission
      dateEarned
      affiliateCommissionResponse
      userPayoutId
      datePaidOut
      returnedActionTrackerId
      lockingDate
      lockingMethod
      merchant {
        id
        name
        categoryName
        childCategoryName
        headingTitle
        shortDescription
        description
        url
        cbcUrl
        cbcCategoryName
        cbcChildCategoryName
        featureStoreId
        isMerchantUsed
        isActive
        commissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listClickTrackers = /* GraphQL */ `
  query ListClickTrackers(
    $filter: ModelClickTrackerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClickTrackers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        cbcMerchantId
        dateCreated
        userAgent
        referrer
        ipAddress
        commissionClicked
        allCommissions
        commissionIdReturned
        totalCommissionEarned
        cbcCommission
        userCommission
        dateEarned
        affiliateCommissionResponse
        userPayoutId
        datePaidOut
        returnedActionTrackerId
        lockingDate
        lockingMethod
        merchant {
          id
          name
          categoryName
          childCategoryName
          headingTitle
          shortDescription
          description
          url
          cbcUrl
          cbcCategoryName
          cbcChildCategoryName
          featureStoreId
          isMerchantUsed
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const clicksByDate = /* GraphQL */ `
  query ClicksByDate(
    $userId: String!
    $dateCreated: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClickTrackerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clicksByDate(
      userId: $userId
      dateCreated: $dateCreated
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        cbcMerchantId
        dateCreated
        userAgent
        referrer
        ipAddress
        commissionClicked
        allCommissions
        commissionIdReturned
        totalCommissionEarned
        cbcCommission
        userCommission
        dateEarned
        affiliateCommissionResponse
        userPayoutId
        datePaidOut
        returnedActionTrackerId
        lockingDate
        lockingMethod
        merchant {
          id
          name
          categoryName
          childCategoryName
          headingTitle
          shortDescription
          description
          url
          cbcUrl
          cbcCategoryName
          cbcChildCategoryName
          featureStoreId
          isMerchantUsed
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayout = /* GraphQL */ `
  query GetPayout($id: ID!) {
    getPayout(id: $id) {
      id
      name
      startDate
      finishDate
      userPayouts {
        items {
          id
          payoutId
          userId
          paymentAmount
          paypalId
          paypalEmail
          dateCreated
          status
          datePaid
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPayouts = /* GraphQL */ `
  query ListPayouts(
    $filter: ModelPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        finishDate
        userPayouts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPayout = /* GraphQL */ `
  query GetUserPayout($id: ID!) {
    getUserPayout(id: $id) {
      id
      payoutId
      userId
      paymentAmount
      paypalId
      paypalEmail
      dateCreated
      status
      datePaid
      payout {
        id
        name
        startDate
        finishDate
        userPayouts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserPayouts = /* GraphQL */ `
  query ListUserPayouts(
    $filter: ModelUserPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPayouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        payoutId
        userId
        paymentAmount
        paypalId
        paypalEmail
        dateCreated
        status
        datePaid
        payout {
          id
          name
          startDate
          finishDate
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userPayoutsByDate = /* GraphQL */ `
  query UserPayoutsByDate(
    $userId: String!
    $dateCreated: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userPayoutsByDate(
      userId: $userId
      dateCreated: $dateCreated
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payoutId
        userId
        paymentAmount
        paypalId
        paypalEmail
        dateCreated
        status
        datePaid
        payout {
          id
          name
          startDate
          finishDate
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLastCommissionId = /* GraphQL */ `
  query GetLastCommissionId($id: ID!) {
    getLastCommissionId(id: $id) {
      id
      commissionId
      createdAt
      updatedAt
    }
  }
`;
export const listLastCommissionIds = /* GraphQL */ `
  query ListLastCommissionIds(
    $filter: ModelLastCommissionIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLastCommissionIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commissionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
