import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { SocialIcon } from 'react-social-icons';
import { Link } from "react-router-dom";

const Styles = styled.div`
    .footerContainer {
        background-color: #000000;
        color: #FFFFFF;
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .footerContainer a {
        color: #FFFFFF;
        line-height: 30px;
    }

    .bottomFooter {
        background-color: #222222;
        color: #FFFFFF;
        font-size: 12px;
        padding: 20px 0 20px 0;
    }

    @media only screen and (max-width: 600px) {
        .footerHead {
            font-size: 18px;
            margin-bottom: 20px;
            margin-top: 40px;
        }
    }

    @media only screen and (mix-width: 601px) {
        .footerHead {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
`;

export const Footer = (props) => {
    return (
        <Styles>
            <Container fluid className="footerContainer">
                <div>
                    <Container>
                        <Row>
                            <Col xs={12} md={4}>
                                <Container>
                                    <Row>
                                        <Col xs={3}>
                                            <SocialIcon url="https://www.facebook.com/cashbackchamp/" fgColor="#FFFFFF" label="Facebook" title="Facebook" />
                                        </Col>
                                        <Col xs={3}>
                                            <SocialIcon url="https://twitter.com/cashback_champ" fgColor="#FFFFFF" label="Twitter" title="Twitter" />
                                        </Col>
                                        <Col xs={3}>
                                            <SocialIcon url="https://www.instagram.com/cashbackchamp/" fgColor="#FFFFFF" label="Instagram" title="Instagram" />
                                        </Col>
                                        <Col xs={3}>
                                            <SocialIcon url="https://www.pinterest.com/cashbackchamp/" fgColor="#FFFFFF" label="PInterest" title="PInterest" />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="footerHead">About</div>
                                <div><Link to="/about-us">About Us</Link></div>
                                <div><Link to="/how-cashback-works">How Cashback Works</Link></div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="footerHead">Support</div>
                                <div><Link to="/affiliate-disclosure">Affiliate Disclosure</Link></div>
                                <div><Link to="/contact-us">Contact Us</Link></div>
                                <div><Link to="/privacy-policy">Privacy Policy</Link></div>
                                <div><Link to="/terms">Terms & Condition</Link></div>
                                <div><Link to="/help">Help</Link></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
            <Container fluid className="bottomFooter">
                <div>
                    <Container>
                        &copy; 2021 CashbackChamp.com. All Rights Reserved.
                    </Container>
                </div>
            </Container>
        </Styles>
    )
}