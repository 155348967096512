import {Helmet} from "react-helmet";
import styled from 'styled-components';

const Styles = styled.div`
    
`;

export const Terms = () => (
    <Styles>
        <Helmet>
            <title>Terms & Conditions - CashbackChamp.com</title>
            <meta name="description" content="Check out our terms and conditions for using CashbackChamp.com" />
        </Helmet>
        <div>
            <h1>Terms & Conditions</h1>
            <p>
                <strong>Introduction:</strong> This Agreement is a legally binding agreement between each individual as a “Visitor” 
                (which means you visit the website and use the service on the site) or a “Member” (which means you joined cashbackchamp.com 
                and created an account). The term “you” refers to the Visitor or Member. The term “we” or “us” refers to cashbackchamp.com
            </p>
            <p>
                <strong>Accepting the Terms:</strong> We put the next section in all-caps to make sure you read it. YOU ACKNOWLEDGE THAT YOU 
                HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY All OF ITS TERMS AND CONDITION. THIS AGREEMENT CONTAINS AN 
                ARBITRATION PROVISION AND A WAIVER OF RIGHTS TO BRING A CLAS ACTION AGAINST US. BY USING THIS SERVICE, YOU AGREE TO THESE PROVISIONS.
            </p>
            <p>
                <strong>Modification and Notice:</strong> From time to time, we may modify the terms and conditions to reflect changes to law and 
                regulation and changes to our Services. These changes will be posted on our website. In addition, we will email the updated 
                Terms & Condition along with the date it was last revised. If you do not agree to the changes you can always exercise your right 
                to stop using the Service. Terms & Conditions (the “Agreement”) affects your rights and applies to your access and use of the website, 
                tools, and products provided by Cashbackchamp.com. 
            </p>
            <p>
                <strong>Description of Services:</strong> We provides you with a suite of tools to locate and obtain deals, coupon, exclusive offers, 
                and other information to help you make informed purchase decision. Retailers can at any time change or cancel any and all deals, coupons, 
                and exclusive offers. We do not offer any warranties on the products, promotions, coupon codes, and exclusive offers being provided by 
                participating retailers and advertisers. We do not endorse the retailers and are not responsible for any aspects of their businesses. 
                We publishes deals, coupon, exclusive offers, and other information links to online retailers. Our purpose is to help members save money 
                on all their purchases. We do not make any purchase on your behalf. 
            </p>
            <p>
                <strong>Website Restriction:</strong> This site “www.cashbackchamp.com” is for personal use only. No individual or organization of any 
                kind shall copy, reproduce, modified this site in any form without prior written consent. This includes but in not limited to logos, 
                photographs, and copyright texts. 
            </p>
            <p>
                <strong>Privacy and your Personal Information:</strong> We are committed to protecting your privacy and will never sell your personal 
                information. For information about Cashbackchamp.com privacy and data protection practices, please read the Privacy Policy found at 
                www.cashbackchamp.com/privacy-policy. By using the Service, you consent to how we collection and use your information as set forth in 
                our privacy policy.
            </p>
            <p>
                <strong>Informal Dispute Resolution:</strong> We would like the opportunity to address your issues or concerns before a formal legal 
                case. You agree to try to resolve this dispute informally first by submitting your issue with  https://www.cashbackchamp.com/contact-us. 
                Please enter “Informal Dispute” in the Subject field along with a description of your claim.
            </p>
            <p>
                <strong>Arbitration Agreement:</strong> You and we agree to arbitrate any and all disputes or controversies arising out of the use of 
                the provided Service, in connection with or related to this Agreement. Arbitration uses a neutral arbitrator instead of a judge or jury 
                and the decision from the arbiter is subject to limited review by the courts. The arbitrator shall have the ability to decide whether 
                any claim is subject to arbitration. The U.S. Federal ArbitrationAct and federal arbitration law shall govern the process and enforcement 
                of this agreement. 
            </p>
            <p>
                <strong>Class Action Waiver:</strong> Neither you nor we will participate in a class action or class-wide arbitration covered by this 
                agreement. As an alternative, you may bring your claims in your local small claims court. You may bring claims only on your own behalf.
            </p>
            <p>
                Last Updated: June 27, 2021
            </p>
        </div>
    </Styles>
)