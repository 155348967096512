import styled from 'styled-components';
import {Helmet} from "react-helmet";

const Styles = styled.div`
    
`;

export const Disclosure = () => (
    <Styles>
        <Helmet>
            <title>Affiliate & Advertising Disclosures - CashbackChamp.com</title>
            <meta name="description" content="Learn how CashbackChamp makes money while keeping our service 100% free." />
        </Helmet>
        <div>
            <h1>Affiliate & Advertising Disclosure</h1>
            <h2>How Does Cashback Champ Make Money?</h2>
            <p>
                It’s actually very simple. When you click on a link from our website and visit a store and make a valid purchase, 
                that store pays us a commission. Then, instead of keeping that commission, we share that commission with you via cash back.
            </p>
            <p>
                Stores like to partner with us because we send traffic to their stores. They pay us in the form of a commission on valid purchases. 
                This helps to grow their brand awareness with shoppers. They sometimes pay us to also promote their brand which means we may feature 
                them prominently on our website home page, mobile app or email newsletter.
            </p>
            <p>
                So, this is why we say it’s a win-win-win scenario for everyone involved. As a shopper, you save money. The store gets a sale they may 
                not have gotten if you had not seen the cashback offer on our website and we make money to continue providing you all of these great deals.
            </p>
            <p>
                If you have any questions, please feel free to Contact Us. We’re here and happy to help.  
            </p>
        </div>
    </Styles>
)